import * as React from 'react';

interface Author {
  firstname: string;
  lastname: string;
}

interface Props {
  author?: Author;
}

const AuthorName = ({ author }: Props) => (
  <span>
    {(author && author.firstname) || 'UNKNOWN'} {(author && author.lastname) || 'AUTHOR'}
  </span>
);
export default AuthorName;
