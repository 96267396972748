import gql from 'graphql-tag';

import { OrgAccess } from './types';

export interface AcceptInviteInput {
  userId: string;
  organisationId: string;
}

export interface AcceptInviteOutput {
  acceptInvite: {
    orgAccesses: OrgAccess[];
  };
}

export const acceptInviteMutation = gql`
  mutation AcceptInvite($userId: ID!, $organisationId: ID!) {
    acceptInvite(id: $userId, organisationId: $organisationId) {
      id
      orgAccesses {
        organisationId
        deactivated
        apps {
          appCode
          roles {
            value
            displayName
          }
        }
        invitation {
          status
        }
      }
    }
  }
`;

export interface DeactivatedInput {
  id: string;
  organisationId: string;
  deactivated?: boolean;
}

export interface DeactivatedOutput {
  deactivateEmployer: {
    orgAccesses: OrgAccess[];
  };
}

export const deactivateEmployerMutation = gql`
  mutation deactivateEmployer($id: ID!, $organisationId: ID!, $deactivated: Boolean) {
    deactivateEmployer(id: $id, organisationId: $organisationId, deactivated: $deactivated) {
      id
      orgAccesses {
        organisationId
        deactivated
        apps {
          appCode
          roles {
            value
            displayName
          }
        }
        invitation {
          status
        }
      }
    }
  }
`;
