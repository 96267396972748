import React, { FC } from 'react';
import { Box } from '@compono/ui';
import styled from 'styled-components';

const PageContainer = styled(Box)`
  a {
    color: ${p => p.theme.global.colors.brand};
  }

  input,
  textarea,
  .Select {
    border: 1.4px solid ${p => p.theme.global.colors['dark-1']} !important;

    input {
      border: 0 !important;
    }
  }
`;

/**
 * As part of TR-447 form styles were moved from being global to only being added on pages that
 * require it via this component.
 * These styles clash with @compono/ui components therefore it was necessary to stop them being
 * applied globally.
 */
export const LegacyPage: FC = ({ children }) => <PageContainer>{children}</PageContainer>;
