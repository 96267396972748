import React from 'react';
import PropTypes from 'prop-types';
import { field } from 'a-plus-forms';
import { CheckboxLabelled, CheckboxLabelledProps } from '@compono/ui';
import { UILayout } from './ReskinLayouts';

type InputProps = React.ComponentPropsWithRef<'input'>;

type Props = Omit<CheckboxLabelledProps, 'onChange' | 'value'> & {
  isIndeterminate?: boolean;
  onChange?: (value: boolean) => void;
  value?: boolean;
};

class Checkbox extends React.Component<Props> {
  static contextTypes = {
    APFProps: PropTypes.object,
  };

  onChange: InputProps['onChange'] = event => {
    this.props.onChange?.(!!event.target.checked);
  };

  render() {
    const { value, onChange, ...rest } = this.props;
    const isChecked = !!value;

    return <CheckboxLabelled checked={isChecked} onChange={this.onChange} {...rest} />;
  }
}

export default field<Props>({ layout: UILayout })(Checkbox);
