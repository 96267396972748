import styled from 'styled-components';

const EmptyTableMessageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 300,
  fontWeight: 600,
});

export default EmptyTableMessageContainer;
