import * as React from 'react';
import { Form as OriginalForm } from '@shortlyster/forms-kit';
import { Error as ValidationError, FormProps } from 'a-plus-forms';

interface GqlError extends Error {
  graphQLErrors: object[];
}

const Form = (props: FormProps) => {
  const { onSubmit: originalOnSubmit, ...rest } = props;
  const onSubmit =
    originalOnSubmit &&
    ((data: object): any => {
      const result = originalOnSubmit(data);

      if (result && result.then && result.catch) {
        // promisish
        return result.catch((error: GqlError) => {
          if (error.graphQLErrors) {
            const errors = parseGraphQLErrors(error.graphQLErrors);
            throw new ValidationError(errors);
          }

          throw error;
        });
      }

      return result;
    });

  return <OriginalForm {...rest} onSubmit={onSubmit} />;
};

export default Form;

// converts graphql errors into A+ form errors hash
function parseGraphQLErrors(errors: any[]): object {
  return errors.reduce((hash, error) => {
    if (error.message) {
      return { ...hash, ...parseBreadbeardError(error.message) };
    }
    console.error(error);
    return { ...hash, '': 'GraphQL validation failed' };
  }, {});
}

// parses the breadbeard back-ticked error message
function parseBreadbeardError(error: string): object {
  const messages = error.match(/`([^`]+)`\s+([^`]+)/g) || [error];
  const trimmedMessages = messages.map(m => m.replace(/(,|\.)?\s+$/, ''));

  return parseErrorMessages(trimmedMessages);
}

function parseErrorMessages(messages: string[]): object {
  return messages.reduce((errors: any, message) => {
    const match = message.match(/^`(.+?)`\s+(.+?)$/);
    const error = match ? { [match[1]]: match[2] } : { _: (errors._ || []).concat(message) };

    return { ...errors, ...error };
  }, {});
}
