import { Grid, RadioLabelled, Text } from '@compono/ui';
import React, { ChangeEvent, FC } from 'react';
import { SkillStatus } from '../types';

interface SkillStatusInputProps {
  onChange: (name: SkillStatus) => void;
  value: string;
  disableReject?: boolean;
  isNewSkill?: boolean;
}

const SkillStatusInput: FC<SkillStatusInputProps> = ({
  onChange,
  value,
  disableReject,
  isNewSkill,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value as SkillStatus);
  };

  return (
    <>
      <Text font="md" noCrop sx={{ paddingBottom: '5px' }}>
        Status
      </Text>
      <Grid
        sx={{
          gridTemplateColumns: 'min-content',
          alignItems: 'center',
          font: 'body',
        }}
      >
        {(value === 'draft' || isNewSkill) && (
          <RadioLabelled
            name="radio-group"
            value="draft"
            label="Draft"
            defaultChecked={value === 'draft'}
            enterKeyHint=""
            onChange={handleChange}
          />
        )}
        <RadioLabelled
          name="radio-group"
          value="approved"
          label="Approve"
          defaultChecked={value === 'approved'}
          enterKeyHint=""
          onChange={handleChange}
        />
        {!isNewSkill && (
          <RadioLabelled
            name="radio-group"
            value="rejected"
            label="Reject"
            defaultChecked={value === 'rejected'}
            enterKeyHint=""
            onChange={handleChange}
            disabled={disableReject}
          />
        )}
      </Grid>
    </>
  );
};

export default SkillStatusInput;
