import { Error as ValidationError } from 'a-plus-forms';

/**
 * helper to validate app appcesses form data.
 * if app's checked but without role select, throw validation error.
 * @param formData receives app appcesses form data
 * e.g. { shortlyster-checkbox: false, shortlyster-roleSelect: "editor" }
 */
export default (formData: { [name: string]: string | boolean | undefined }): void => {
  const CHECKBOX_FORM_TAG = '-checkbox';
  const ROLE_FORM_TAG = '-roleSelect';
  const formDataArr = Object.entries(formData);
  const hasAppChecked = formDataArr.find(
    ([formName, formValue]) => formName.includes(CHECKBOX_FORM_TAG) && !!formValue
  );

  if (!hasAppChecked) {
    throw new ValidationError('You must select at least one application access');
  }

  formDataArr.forEach(([formName, formValue], _, originalArr) => {
    if (formName.includes(CHECKBOX_FORM_TAG)) {
      const appCode = formName.split('-')[0];
      const isChecked = formValue;
      const hasRoleSelect = originalArr?.find(
        ([name]) => name === `${appCode}${ROLE_FORM_TAG}`
      )?.[1];
      if (isChecked && !hasRoleSelect) {
        throw new ValidationError({
          [`${appCode}${ROLE_FORM_TAG}`]: 'You must select the access for this user if the app is checked',
        });
      }
    }
  });
};
