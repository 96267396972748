import styled from 'styled-components';

const FormFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2em;
  justify-content: space-between;
`;

export default FormFooter;
