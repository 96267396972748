import * as React from 'react';
import { field } from 'a-plus-forms';
import { Button } from '@compono/ui';
import styled from 'styled-components';
import { OrgAccess } from './types';
import { OrgAccessesTable } from './OrgAccessesTable';

const StyledButton = styled(Button)`
  margin-top: 0.5rem;
`;
interface Props {
  userId?: string;
  openModal: (e: MouseEvent) => void;
  openSpecifiedModal: (orgAccess: OrgAccess) => void;
}

const AccessesPicker = ({ openModal, userId, openSpecifiedModal }: Props) => (
  <>
    <OrgAccessesTable userId={userId} openSpecifiedModal={openSpecifiedModal} />
    <StyledButton onClick={openModal}>Add an organisation</StyledButton>
  </>
);

export default field<Props>({ array: true })(AccessesPicker);
