export const clearTypeName = <T extends { [key: string]: any }>(data: T): T => {
  const result = {} as { [key: string]: any };
  Object.keys(data).forEach(key => {
    if (key === '__typename') return;
    const value = data[key];
    if (value instanceof Array) {
      result[key] = value.map((e: any) => {
        if (e instanceof Object) {
          return clearTypeName(e);
        }
        return e;
      });
    } else if (value instanceof Object) {
      result[key] = clearTypeName(data[key]);
    } else {
      result[key] = value;
    }
  });

  return result as T;
};

/**
 * Omits key/value pair from object if the value is null
 * Nulls are returned by graphql for undefined and null is considered a value in our validation
 */
export function omitNull<T extends object>(obj: T, recursive = false): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_key, val]) => val !== null)
      .map(([key, val]) => {
        if (Array.isArray(val)) {
          return [key, val.map(v => omitNull(v, true))];
        }

        if (typeof val === 'object') {
          return [key, omitNull(val, true)];
        }

        return [key, val];
      })
  );
}
