import styled from 'styled-components';

export default styled.table.attrs({
  className: 'hover stack',
})`
  width: 100%;

  th {
    text-align: left;
  }
`;
