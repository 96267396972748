import React, { FC, useState } from 'react';
import { createErrorToast, createSuccessToast } from 'components/Toast';
import { Skill } from './types';
import { SkillNameInput, SkillDetails, SkillDialog } from './Components';
import { SkillService } from './SkillService';

interface ApproveSkillDialogProps {
  closeDialog: () => void;
  skill: Skill;
  defaultChecked?: boolean;
}

const ApproveSkillDialog: FC<ApproveSkillDialogProps> = ({ closeDialog, skill }) => {
  const [currentSkill, setSkill] = useState(skill);
  const [approveSkill, { loading }] = SkillService.useApproveSkill({
    refetchQueries: ['Skills'],
  });

  const onSubmit = async () => {
    try {
      await approveSkill({
        variables: {
          id: currentSkill.id,
          name: currentSkill.name,
        },
      });

      closeDialog();

      createSuccessToast({
        title: 'Skill status updated',
        message: `Skill "${currentSkill.name}" has been successfully approved.`,
      });
    } catch {
      createErrorToast({
        title: 'Error',
        message: `Something went wrong updating the skill, please try again later.`,
      });
    }
  };

  return (
    <SkillDialog
      title="Approve Skill"
      disabled={!currentSkill.name || loading}
      onClose={closeDialog}
      onSubmit={onSubmit}
    >
      <SkillNameInput
        value={currentSkill.name}
        onChange={name => {
          setSkill({
            ...currentSkill,
            name,
          });
        }}
        onPressEnter={onSubmit}
      />
      <SkillDetails skill={currentSkill} includeStatus />
    </SkillDialog>
  );
};

export default ApproveSkillDialog;
