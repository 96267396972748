import styled from 'styled-components';
import * as React from 'react';
import { format } from 'date-fns';
import { useQuery } from '@apollo/react-hooks';
import {
  Section,
  TitleSection,
  Breadcrumb,
  H1,
  Subtitle2,
  Table,
  Pagination,
} from '@shortlyster/ui-kit';
import useQueryParams from 'src/lib/useQueryParams';
import { useParams } from 'react-router-dom';
import { organisationJobsQuery, OrganisationJobsQuery, Job } from './queries';
import Link from '../../../components/Link';
import getCustomerSubtitle from '../../../lib/getCustomerSubtitle';
import OrganisationNavBar from './OrganisationNavBar';
import EmptyTableMessageContainer from './EmptyTableMessageContainer';

const { Col, Row } = Table;

const Container = styled.div({
  '.capital': {
    textTransform: 'capitalize',
  },
});

const JobsTable = ({ jobs, isNews }: { jobs: Job[]; isNews: boolean }) => {
  const headings = ['Job name', 'Status', 'Created'];

  if (isNews) {
    headings.splice(1, 0, 'Package');
  }

  return (
    <Table headings={headings}>
      {jobs.map(job => (
        <Row id={job.id} key={job.id} headings={headings}>
          <Col>{job.title}</Col>
          {isNews && <Col className="capital">{job.package}</Col>}
          <Col className="capital">{job.status}</Col>
          <Col>{format(new Date(job.createdAt), 'd MMMM yyyy')}</Col>
        </Row>
      ))}
    </Table>
  );
};

const OrganisationJobsPage: React.FC = () => {
  const { id } = useParams();
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const { data, loading } = useQuery<OrganisationJobsQuery>(organisationJobsQuery, {
    variables: { id, page },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <p>Loading...</p>;
  if (!data) return <p>Organisation not found...</p>;

  const organisation = data.organisations[0];
  const isNews = organisation?.metadata?.source === 'newscorp';

  return (
    <Container>
      <TitleSection>
        <Breadcrumb>
          <Link to="/organisations">Organisations</Link>
          <Link to="" aria-current={true}>
            {organisation.name}
          </Link>
        </Breadcrumb>
        <H1>{organisation.name}</H1>
        <Subtitle2>{getCustomerSubtitle(loading, isNews)}</Subtitle2>
      </TitleSection>
      <OrganisationNavBar organisationId={id as string} active="jobs" />
      <Section>
        <JobsTable jobs={organisation.listings} isNews={isNews} />
        {organisation.listings.length === 0 && (
          <EmptyTableMessageContainer>
            There are no jobs associated with this organisation
          </EmptyTableMessageContainer>
        )}
        <Pagination link={Link} page={page} pages={organisation.listingsCount.pages || 1} />
      </Section>
    </Container>
  );
};

export default OrganisationJobsPage;
