import styled from 'styled-components';
import { ActionsMenu, EditIcon, SubmenuLink, Link, Table } from '@shortlyster/ui-kit';
import history from 'lib/history';
import { User } from './queries';
import AcceptInvite from '../../Employers/AcceptInvite';
import DeactivateUser from '../../Employers/DeactivateUser';
import { Product } from '../queries';

const { Col, Row } = Table;

const ActionCol = styled(Col)`
  & > div {
    @media (${p => p.theme.tabletPortraitAndUp}) {
      padding-left: 0.5rem;
    }
  }
`;

const Role = styled.p`
  margin: 0;
  text-transform: capitalize;
`;

type Props = {
  user: User;
  products: Product[];
  headings: string[];
  onUpdateAccess: () => Promise<void>;
};

const UserRow = ({ user, products, headings, onUpdateAccess }: Props) => {
  const { orgAccesses } = user;
  const orgAccess = orgAccesses?.[0];
  const { apps } = orgAccess;
  const userId: string = user.id;

  const getAppName = (appCode: string) =>
    products?.find((product: { code: string }) => product.code === appCode)?.description || appCode;

  const renderMenuItem = () => (
    <>
      <SubmenuLink icon={EditIcon} onClick={() => history.changeDomainTo(`/employers/${userId}`)}>
        Edit
      </SubmenuLink>
      <AcceptInvite userId={userId} orgAccess={orgAccess} onAcceptInvite={onUpdateAccess} />
      <DeactivateUser userId={userId} orgAccess={orgAccess} onDeactivateUser={onUpdateAccess} />
    </>
  );

  const renderRoles = () =>
    apps?.map(app => (
      <Role key={`${userId}-${app.appCode}`}>
        {getAppName(app.appCode)} ({app.roles?.[0].displayName})
      </Role>
    ));

  return (
    <Row id={userId} key={userId} headings={headings}>
      <Col className="capital">
        <Link to={`/employers/${userId}`}>{user.firstname}</Link>
      </Col>

      <Col className="capital">
        <Link to={`/employers/${userId}`}>{user.lastname}</Link>
      </Col>

      <Col className="capital">
        <Link to={`/employers/${userId}`}>{user.email}</Link>
      </Col>

      <Col className="capital">{renderRoles()}</Col>
      <Col className="capital">{user.orgAccesses?.[0].deactivated ? 'Deactivated' : 'Active'}</Col>
      <Col className="capital">{user.orgAccesses?.[0].invitation?.status || 'N/A'}</Col>
      <ActionCol>
        <ActionsMenu>{renderMenuItem()}</ActionsMenu>
      </ActionCol>
    </Row>
  );
};

export default UserRow;
