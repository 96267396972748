import gql from 'graphql-tag';

export const FetchProfile = gql`
  query FetchProfile {
    profile {
      id
      firstName
      lastName
      accesses {
        genericApps {
          appCode
          createdAt
        }
      }
    }
  }
`;

export const RefreshToken = gql`
  mutation RefreshToken {
    refreshToken
  }
`;
