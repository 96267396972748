import { MutationTuple, MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { mutations } from './gql';

interface CreateMutation {
  createSkill: {
    name: string;
    status: string;
  };
}

interface UpdateMutation {
  updateSkill: {
    id: string;
    name: string;
    status: string;
  };
}

interface ApproveMutation {
  approveSkill: {
    id: string;
    name: string;
    status: string;
  };
}

interface RejectMutation {
  rejectSkill: {
    id: string;
    name: string;
    status: string;
  };
}

const useCreateSkill = (
  options?: MutationHookOptions
): MutationTuple<CreateMutation, Record<string, any>> =>
  useMutation<CreateMutation>(mutations.createSkill, options);

const useUpdateSkill = (
  options?: MutationHookOptions
): MutationTuple<UpdateMutation, Record<string, any>> =>
  useMutation<UpdateMutation>(mutations.updateSkill, options);

const useApproveSkill = (
  options?: MutationHookOptions
): MutationTuple<ApproveMutation, Record<string, any>> =>
  useMutation<ApproveMutation>(mutations.approveSkill, options);

const useRejectSkill = (
  options?: MutationHookOptions
): MutationTuple<RejectMutation, Record<string, any>> =>
  useMutation<RejectMutation>(mutations.rejectSkill, options);

export const SkillService = {
  useCreateSkill,
  useUpdateSkill,
  useApproveSkill,
  useRejectSkill,
};
