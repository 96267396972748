import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryParams from 'src/lib/useQueryParams';
import { Flex, Loader, Pagination } from '@compono/ui';
import { SkillsFilterBar } from 'src/features/Skills/SkillsFilterBar';
import { SkillsResultBar } from 'features/Skills/SkillsResultBar';
import { SkillsEmpty, SkillsError } from 'features/Skills/SkillsEmptyStates';
import { CreateSkillHeaderBar } from 'src/features/Skills/CreateSkillHeaderBar';

import List from './SkillsList';
import { Skill } from './types';

interface SkillsQuery {
  skills?: Skill[];
  skillsCount: {
    pages: number;
    total: number;
  };
}

const PAGE_SIZE = 25;
export const query = gql`
  query Skills(
    $page: Int!
    $status: String
    $source: SkillSource
    $search: String
    $orderBy: SkillOrderBy
    $order: Order
  ) {
    skills(
      page: $page
      status: $status
      source: $source
      search: $search
      orderBy: $orderBy
      order: $order
    ) {
      id
      name
      status
      createdAt
      updatedAt
      source
      author {
        firstname
        lastname
      }
    }
    skillsCount(status: $status, source: $source, search: $search) {
      pages
      total
    }
  }
`;

const SkillsPage: FC = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const status = queryParams.get('status') || undefined;
  const source = queryParams.get('source') || undefined;
  const search = queryParams.get('search') || undefined;

  const getOrder = () => {
    if (search) return undefined; // Use default ordering based on match score
    if (status === 'draft') {
      return { orderBy: 'createdAt', order: 'desc' };
    }

    return { orderBy: 'name', order: 'asc' };
  };

  const variables = {
    page,
    status,
    search,
    source,
    ...getOrder(),
  };
  const { data, loading, error, refetch } = useQuery<SkillsQuery>(query, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  const queryResult = !loading && data ? data : undefined;
  const skills = queryResult?.skills || [];
  const { pages = 1, total } = queryResult?.skillsCount || {};

  const getItemRange = () => {
    const startItem = (variables.page - 1) * PAGE_SIZE + 1;
    const endItem = startItem + skills.length - 1;
    return error || !total ? '0' : `${startItem} - ${endItem}`;
  };

  const updateQuery = (key: string, value?: string) => {
    const searchParams = new URLSearchParams(location.search);
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    // Reset to page 1 when other parameters are changed
    searchParams.set('page', '1');
    history.replace({ search: searchParams.toString() });
  };

  const createSearchForPage = (newPage: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', newPage.toString());
    return `${location.pathname}?${searchParams.toString()}`;
  };

  const hasChangedFilters = status !== 'draft';
  const handleClearSearch = () => updateQuery('search', undefined);
  const handleClearFilters = () => history.replace({ search: '' });

  const getContent = () => {
    if (error) return <SkillsError onRetryClick={() => refetch()} />;
    if (!queryResult) return null;

    return skills.length ? (
      <>
        <List skills={skills} />
        {pages > 1 && (
          <Pagination sx={{ marginTop: 7 }} to={createSearchForPage} page={page} pages={pages} />
        )}
      </>
    ) : (
      <SkillsEmpty
        searchTerm={variables.search}
        onClearFilters={handleClearFilters}
        hasChangedFilters={hasChangedFilters}
      />
    );
  };

  return (
    <Flex
      sx={{
        paddingBottom: 9,
        flexDirection: 'column',
        minHeight: 'calc(100vh - 56px)', // 56px is the header height
      }}
    >
      <CreateSkillHeaderBar updateQuery={updateQuery} />

      <SkillsFilterBar
        status={status || 'all'}
        source={source || 'all'}
        onStatusChange={value => updateQuery('status', value === 'all' ? undefined : value)}
        onSourceChange={value => updateQuery('source', value === 'all' ? undefined : value)}
      />

      {loading ? (
        <Flex sx={{ flex: 1, alignItems: 'center' }}>
          <Loader alignment={'center'} label={'Loading...'} />
        </Flex>
      ) : (
        <SkillsResultBar
          itemRange={getItemRange()}
          showClearSearch={!!search}
          onClearSearch={handleClearSearch}
        />
      )}
      {getContent()}
    </Flex>
  );
};

export default SkillsPage;
