/* eslint-disable global-require */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Box, Layer } from 'grommet';
import App from './App';

const SoCringeAvoidTreeShakingGrommetLayerUsedInUiKit = () => (
  <>
    <Layer full={false} modal={false} style={{ display: 'none' }} />
    <Box style={{ display: 'none' }} />
  </>
);

const rootEl = document.getElementById('app');

ReactDOM.render(
  <>
    <SoCringeAvoidTreeShakingGrommetLayerUsedInUiKit />
    <App />
  </>,
  rootEl
);
