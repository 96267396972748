import { Box, Flex, Select, SelectableOptionT } from '@compono/ui';
import { FC, useMemo } from 'react';
import * as React from 'react';

const statusOptions = [
  { value: 'all', label: 'All skill status' },
  { value: 'draft', label: 'Draft' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];

const sourceOptions = [
  { value: 'all', label: 'All skill sources' },
  { value: 'candidate', label: 'Candidate' },
  { value: 'employer', label: 'Employer' },
  { value: 'compono', label: 'Compono' },
];

const prepareSelectOptions = (options: SelectableOptionT[], selectedValue: string) =>
  options.map(o => ({ ...o, selected: o.value === selectedValue }));

interface SkillsFilterBarProps {
  status?: string;
  source?: string;
  onSourceChange: (value: string) => void;
  onStatusChange: (value: string) => void;
}

export const SkillsFilterBar: FC<SkillsFilterBarProps> = ({
  status,
  source,
  onSourceChange,
  onStatusChange,
}) => {
  const selectStatusOptions = useMemo(() => prepareSelectOptions(statusOptions, status), [status]);
  const selectSourceOptions = useMemo(() => prepareSelectOptions(sourceOptions, source), [source]);

  return (
    <Flex sx={{ gap: 5, flexWrap: ['wrap', 'nowrap'] }}>
      <Box sx={{ width: ['100%', '220px'] }}>
        <Select
          options={selectSourceOptions}
          onChange={value => onSourceChange((value as SelectableOptionT).value)}
        />
      </Box>
      <Box sx={{ width: ['100%', '220px'] }}>
        <Select
          options={selectStatusOptions}
          onChange={value => onStatusChange((value as SelectableOptionT).value)}
        />
      </Box>
    </Flex>
  );
};
