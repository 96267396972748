import { Component } from 'react';
import { getApolloContext } from '@apollo/react-common';
import { AsyncSelectSingle, AsyncSelectSingleProps } from '@shortlyster/forms-kit';
import gql from 'graphql-tag';
import { field, InputProps } from 'a-plus-forms';
import { getOrganisationQuery } from './queries';

export const searchOrganisationsQuery = gql`
  query SearchOrganisations($search: String!) {
    organisations(search: $search, page: 1) {
      id
      name
    }
  }
`;

const organisationToOption = ({ id, name }: { id: string; name: string }) => ({
  value: {
    id,
    name,
  },
  label: name,
});

class OrganisationsSelect extends Component<AsyncSelectSingleProps & InputProps> {
  static contextType = getApolloContext();

  rehydrateOptions = ({ id }: { id: string; name: string }) => {
    const { client } = this.context;
    return client
      .query({ query: getOrganisationQuery, variables: { id } })
      .then(({ data: { organisations = [] } }) => organisationToOption(organisations?.[0] || {}));
  };

  fetchOptions = (search: String) => {
    const { client } = this.context;
    return client
      .query({
        query: searchOrganisationsQuery,
        variables: { search },
      })
      .then(({ data: { organisations = [] } }) => organisations.map(organisationToOption));
  };

  render() {
    return (
      <AsyncSelectSingle
        {...this.props}
        label="Organisation"
        rehydrateOptions={this.rehydrateOptions}
        fetchOptions={this.fetchOptions}
      />
    );
  }
}

export default field<AsyncSelectSingleProps>()(OrganisationsSelect);
