import { getApolloContext } from '@apollo/react-common';
import { useContext, useCallback, useReducer } from 'react';
import orgAccessContext, { initialState, reducer } from './orgAccessContext';

const { Provider } = orgAccessContext;

const OrgAccessProvider = ({ children }) => {
  const { client } = useContext(getApolloContext());

  const [state, reducerDispatch] = useReducer(reducer, initialState);

  const dispatch = useCallback(reducerDispatch, [client]);
  return <Provider value={[state, dispatch]}>{children}</Provider>;
};

export default OrgAccessProvider;
