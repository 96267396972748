import * as qs from 'query-string';

const { createBrowserHistory } = require('history');

const UUID_RE = /[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}/gi;

export const browserHistory = createBrowserHistory();

const getQuery = () => {
  const { search } = browserHistory.location;
  if (!search || search.length <= 1) return {};
  const { page, ...parsed } = qs.parse(search.substring(1));
  // convert page from string to number (for pagination component)
  return { ...parsed, ...(page ? { page: parseInt(page, 10) } : {}) };
};

const getLocation = () =>
  Object.assign({}, browserHistory.location, {
    // provides a parsed query object in .location.query
    get query(): any {
      return getQuery();
    },
  });

export default {
  goBack: browserHistory.goBack,
  listen: browserHistory.listen,
  get location() {
    return getLocation();
  },
  push: (path: string) => browserHistory.push(path),
  replace: (path: string) => browserHistory.replace(path),
  isChangingDomain: false,
  getParamId: () => {
    const idParam = getLocation().pathname.match(UUID_RE);
    return idParam && idParam.length && idParam[0];
  },

  // a hack around, essentially JSDOM doesn't support navigation to new domains
  // and it doesn't allow to spoof the `.href` accessor either, so i'm declaring
  // a new method here, wich we can mock in tests
  changeDomainTo(newDomainLocation: string) {
    if (process.env.NODE_ENV !== 'test') {
      document.location.href = newDomainLocation;

      this.isChangingDomain = true;
    }
  },
};
