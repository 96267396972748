import gql from 'graphql-tag';

export const AdminsQuery = gql`
  query AdminsQuery {
    adminUsers {
      id
      firstname
      lastname
      email
      accessGranted
    }
  }
`;

export const NonAdminsQuery = gql`
  query NonAdminsQuery($search: String) {
    nonAdminUsers(search: $search) {
      id
      firstname
      lastname
      email
    }
  }
`;

export const UpdateAdminAccess = gql`
  mutation UpdateAdminAccess($userId: ID!, $isAdmin: Boolean!) {
    updateAccess(userId: $userId, isAdmin: $isAdmin) {
      id
      accessGranted
    }
  }
`;
