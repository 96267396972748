import styled from 'styled-components';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Text, Alert, Stack } from '@compono/ui';
import { FormFooter, Form } from 'src/components/Forms';
import HrisIntegrations from './HrisIntegrations';
import {
  updateHrisIntegrationMutation,
  UpdateHrisIntegrationMutation,
  UpdateHrisIntegrationMutationInput,
  OrganisationIntegrationsQuery,
  getIntegrationsQuery,
  IntegrationInput,
  Integration,
} from './queries';

const StyledText = styled(Text)<{ marginbottom: number }>`
  margin-bottom: ${p => p.marginbottom}rem;
`;

const Container = styled.div`
  margin: 2rem 0;
`;

export type Props = {
  organisationId: string;
};

export type AlertState = {
  title: string;
  type: 'critical' | 'success';
  message: string;
};

const HRIntegrations = ({ organisationId }: Props) => {
  const [hrisIntegrationValue, setHrisIntegrationValue] = useState<Integration[] | undefined>(
    undefined
  );
  const [alert, setAlert] = useState<AlertState>();
  const { loading: queryLoading } = useQuery<OrganisationIntegrationsQuery>(getIntegrationsQuery, {
    variables: { id: organisationId },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      setAlert({ title: 'Error', type: 'critical', message: error.message });
    },
    onCompleted: integrationsQueryData => {
      const hrisIntegrations = integrationsQueryData?.integrations?.hris;
      setHrisIntegrationValue(hrisIntegrations);
    },
  });

  const [updateOrganisation, { loading: updating }] = useMutation<
    UpdateHrisIntegrationMutation,
    UpdateHrisIntegrationMutationInput
  >(updateHrisIntegrationMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: getIntegrationsQuery, variables: { id: organisationId } }],
    onError: error => {
      setAlert({ title: 'Error', type: 'critical', message: error.message });
    },
  });

  const onSubmit = async (form: { hris: Integration[] }): Promise<void> => {
    const values: IntegrationInput[] =
      form.hris?.map(({ name, enabled, fields }) => ({
        name,
        enabled,
        fields: fields?.filter(f => f.enabled).map(f => f.key),
      })) || [];

    const result = await updateOrganisation({
      variables: {
        id: organisationId,
        values,
      },
    });
    if (result?.data) {
      setAlert({
        title: 'Success',
        type: 'success',
        message: 'Update integration settings successful',
      });
    } else {
      setAlert({
        title: 'Error',
        type: 'critical',
        message: 'Update integration settings failed',
      });
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Stack>
        <StyledText font="h4" marginbottom={1.5}>
          HR management system integration
        </StyledText>
        {alert && (
          <Container>
            <Alert title={alert.title} onDismiss={() => setAlert(undefined)} tone={alert.type}>
              <div>{alert.message}</div>
            </Alert>
          </Container>
        )}
        {queryLoading ? <p>Loading...</p> : <></>}
        {hrisIntegrationValue ? (
          <HrisIntegrations
            name="hris"
            value={hrisIntegrationValue}
            disabled={updating}
            onChange={(integrations: Integration[]) => {
              setHrisIntegrationValue(integrations);
            }}
          />
        ) : (
          <></>
        )}
        <FormFooter>
          <Button type="submit" disabled={updating || queryLoading}>
            Save integration settings
          </Button>
        </FormFooter>
      </Stack>
    </Form>
  );
};

export default HRIntegrations;
