import {
  Button,
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogHeading,
  DialogPrimaryActions,
} from '@compono/ui';
import React, { FC } from 'react';

interface SkillDialogProps {
  title: string;
  submitButton?: string;
  disabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const SkillDialog: FC<SkillDialogProps> = ({
  title,
  disabled,
  submitButton = 'Save',
  onSubmit,
  onClose,
  children,
}) => {
  const handleOnSubmit = async () => {
    await onSubmit();
  };

  return (
    <Dialog
      data-testid="dialog"
      isOpen={true}
      aria-describedby="dialog-description"
      aria-labelledby="dialog-title"
    >
      <DialogHeading id="dialog-title">{title}</DialogHeading>
      <DialogBody id="dialog-description">{children}</DialogBody>
      <DialogPrimaryActions>
        <Button look="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          look="primary"
          type="submit"
          onClick={handleOnSubmit}
          data-testid="submitButton"
          disabled={disabled}
        >
          {submitButton}
        </Button>
      </DialogPrimaryActions>
      <DialogCloseButton onClick={onClose} />
    </Dialog>
  );
};

export default SkillDialog;
