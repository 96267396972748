import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { field } from 'a-plus-forms';
import { Select } from '@shortlyster/forms-kit';
import { QualificationSubcategory, QualificationField } from './types';

interface Qualifications {
  qualificationSubcategories?: QualificationSubcategory[];
  qualificationFields?: QualificationField[];
}

const query = gql`
  query Qualifications {
    qualificationFields {
      id
      name
    }
    qualificationSubcategories {
      id
      name
      fieldId
    }
  }
`;

interface Props {
  value?: string;
  onChange?: (props: string | void) => void;
}

const IndentSpan = styled.span`
  padding-left: 1rem;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const QualificationSubcategorySelect: React.FC<Props> = ({ value, ...rest }) => {
  const { data, loading } = useQuery<Qualifications>(query);
  const { qualificationSubcategories = [], qualificationFields = [] } = data ?? {};

  const options = qualificationFields.reduce((opts, qualField) => {
    opts.push({ label: <BoldSpan>{qualField.name}</BoldSpan>, value: qualField.id });

    qualificationSubcategories.forEach(cat => {
      if (cat.fieldId === qualField.id) {
        opts.push({
          value: cat.id,
          label: <IndentSpan>{cat.name}</IndentSpan>,
        });
      }
    });

    return opts;
  }, [] as Array<{ value: string; label: JSX.Element }>);

  return (
    <Select
      {...rest}
      multiple={true}
      options={options}
      loading={loading}
      value={value}
      layout={null}
    />
  );
};

export default field()(QualificationSubcategorySelect);
