import React from 'react';
import { ActionsMenu, SubmenuLink, EditIcon, Table } from '@shortlyster/ui-kit';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import useOrgAccess from './access/useOrgAccess';
import { GetOrganisation, OrgAccess } from './types';
import { getOrganisationQuery, productsQuery } from '../Organisations/queries';
import AcceptInvite from './AcceptInvite';
import DeactivateUser from './DeactivateUser';

const { Row, Col } = Table;

const CapitalizedCol = styled(Col)`
  text-transform: capitalize;
`;

interface AppAccessRowProps {
  userId?: string;
  orgAccess: OrgAccess;
  index: number;
  openSpecifiedModal: (orgAccess: OrgAccess) => void;
}

const AppAccessRow: React.FC<AppAccessRowProps> = ({
  userId,
  orgAccess,
  openSpecifiedModal,
  index,
}) => {
  const [, dispatch] = useOrgAccess();
  const { data: productsInfo } = useQuery(productsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const orgId = orgAccess?.organisation?.id || orgAccess?.organisationId;
  const { data: orgData } = useQuery<GetOrganisation>(getOrganisationQuery, {
    variables: { id: orgId },
  });
  const orgName = orgAccess?.organisation?.name || orgData?.organisations?.[0]?.name;

  const onUpdateAccess = async (orgAccesses: OrgAccess[]): Promise<void> => {
    dispatch({
      type: 'setOrgAccesses',
      payload: orgAccesses,
    });
  };
  const status = orgAccess?.deactivated || 'Active';
  const invitationStatus = orgAccess?.invitation?.status || 'N/A';

  const getAppName = (code: string) =>
    productsInfo?.products?.find(p => p.code === code)?.description || code;

  return (
    <Row id={`orgAccessRow_${index}`}>
      <Col>{orgName}</Col>
      <CapitalizedCol>
        {orgAccess?.apps?.map(app => (
          <div key={`${orgId}_${app.appCode}`}>
            {getAppName(app.appCode)} ({app.roles?.[0].displayName})
          </div>
        ))}
      </CapitalizedCol>
      <Col>{status}</Col>
      <CapitalizedCol>{invitationStatus}</CapitalizedCol>
      <Col>
        <ActionsMenu>
          <SubmenuLink onClick={() => openSpecifiedModal(orgAccess)} icon={EditIcon}>
            Edit
          </SubmenuLink>
          <AcceptInvite userId={userId} orgAccess={orgAccess} onAcceptInvite={onUpdateAccess} />
          <DeactivateUser userId={userId} orgAccess={orgAccess} onDeactivateUser={onUpdateAccess} />
        </ActionsMenu>
      </Col>
    </Row>
  );
};

interface OrgAccessesTableProps {
  openSpecifiedModal: (orgAccess: OrgAccess) => void;
  userId?: string;
}

export const OrgAccessesTable: React.FC<OrgAccessesTableProps> = ({
  userId,
  openSpecifiedModal,
}) => {
  const [{ orgAccesses }] = useOrgAccess();

  return (
    <Table headings={['', '', 'Status', 'Invite status', 'Actions']}>
      {orgAccesses?.map((orgAccess: OrgAccess, index: number) => (
        <AppAccessRow
          userId={userId}
          key={`AppAccessRow_${index}`}
          orgAccess={orgAccess}
          openSpecifiedModal={openSpecifiedModal}
          index={index}
        />
      ))}
    </Table>
  );
};
