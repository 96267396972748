import * as React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Section, TitleSection, Breadcrumb, H1, Subtitle2 } from '@shortlyster/ui-kit';
import { useParams, useHistory } from 'react-router-dom';
import { LegacyPage } from 'components/LegacyPage';
import { Box, Loader } from '@compono/ui';
import {
  organisationProductsQuery,
  OrganisationProductsQuery,
  OrgProductStatus,
  UpdateOrganisationProductsMutation,
  updateOrganisationProductsMutation,
} from '../queries';
import OrganisationForm, { NewOrganisation } from '../OrganisationForm';
import Link from '../../../components/Link';
import getCustomerSubtitle from '../../../lib/getCustomerSubtitle';
import OrganisationNavBar from './OrganisationNavBar';
import OrganisationProductsForm from '../OrganisationProductsForm';
import { clearTypeName } from '../../../utils';

const UpdateOrganisationPage: React.FC = () => {
  const { id } = useParams();
  const [pollProducts, setPollProducts] = React.useState(false);
  const { loading, data } = useQuery<OrganisationProductsQuery>(organisationProductsQuery, {
    variables: { id },
    fetchPolicy: 'network',
    pollInterval: pollProducts ? 3000 : 0, // check for updates every 3 seconds
  });

  const products = data?.organisations?.[0]?.products || [];
  const hasProvisioningProducts = products.some(p =>
    [OrgProductStatus.PROVISIONING, OrgProductStatus.PROVISIONING_REQUIRED].includes(
      p.productStatus
    )
  );

  React.useEffect(() => {
    if (!pollProducts && hasProvisioningProducts) {
      setPollProducts(true);
    } else if (pollProducts && !hasProvisioningProducts) {
      setPollProducts(false);
    }
  }, [hasProvisioningProducts]);

  const [updateOrganisation, { loading: updatingOrganisation }] =
    useMutation<UpdateOrganisationProductsMutation>(updateOrganisationProductsMutation);

  if (loading) return <p>Loading...</p>;
  if (!data) return <p>Organisation not found...</p>;

  const organisation = clearTypeName(data?.organisations?.[0]);
  const isNews = organisation?.metadata?.source === 'newscorp';

  const onSubmit = async ({ products }: NewOrganisation) => {
    const variables = {
      products: products.map(({ productStatus, ...p }) => ({
        ...p,
        // remove productInstanceId for new products
        productInstanceId: p.productInstanceId?.includes('new') ? undefined : p.productInstanceId,
        // the following fields can only by updated by the backend
        productProvisioningError: undefined,
        productExternalId: undefined,
      })),
      id: data.organisations[0].id,
    };
    await updateOrganisation({
      variables,
      refetchQueries: ['OrganisationProductsQuery'],
    });
  };

  return (
    <LegacyPage>
      <TitleSection>
        <Breadcrumb>
          <Link to="/organisations">Organisations</Link>
          <Link to="" aria-current={true}>
            {organisation.name}
          </Link>
        </Breadcrumb>
        <H1>{organisation.name}</H1>
        <Subtitle2>{getCustomerSubtitle(loading, isNews)}</Subtitle2>
      </TitleSection>
      <OrganisationNavBar organisationId={id as string} active="products" />
      <Section>
        {data && (
          <OrganisationProductsForm
            onSubmit={onSubmit}
            products={organisation.products || []}
            disabled={updatingOrganisation || hasProvisioningProducts}
          />
        )}
      </Section>
    </LegacyPage>
  );
};

export default UpdateOrganisationPage;
