import { ActionBar, ActionBarGroup } from '@shortlyster/ui-kit';
import ActionBarTab from '../../../components/ActionBarTab';

export type OrganisationNavBarProps = {
  organisationId: string;
  active: 'details' | 'products' | 'users' | 'jobs' | 'integrations';
};

const OrganisationNavBar: React.FC<OrganisationNavBarProps> = ({ organisationId, active }) => (
  <ActionBar>
    <ActionBarGroup>
      <ActionBarTab
        aria-current={active === 'details'}
        to={`/organisations/${organisationId}`}
        replace
      >
        Details
      </ActionBarTab>
      <ActionBarTab
        aria-current={active === 'products'}
        to={`/organisations/${organisationId}/products`}
        replace
      >
        Products
      </ActionBarTab>
      <ActionBarTab
        aria-current={active === 'users'}
        to={`/organisations/${organisationId}/users`}
        replace
      >
        Users
      </ActionBarTab>
      <ActionBarTab
        aria-current={active === 'jobs'}
        to={`/organisations/${organisationId}/jobs`}
        replace
      >
        Jobs
      </ActionBarTab>
      <ActionBarTab
        aria-current={active === 'integrations'}
        to={`/organisations/${organisationId}/integrations`}
        replace
      >
        Integrations
      </ActionBarTab>
    </ActionBarGroup>
  </ActionBar>
);

export default OrganisationNavBar;
