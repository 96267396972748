/* eslint react/no-multi-comp: off */
import * as React from 'react';
import { color } from '@shortlyster/ui-kit';
import { LayoutProps as BasicLayoutProps } from 'a-plus-forms'; // eslint-disable-line
import styled, { css } from 'styled-components'; // eslint-disable-line
import { upperFirst } from 'src/lib/formattingHelpers';

export type LayoutProps = BasicLayoutProps & {
  className?: string;
  disabled?: boolean;
  help?: string;
  value?: any;
  id?: string;
};

const RTEStyles = css`
  .rte-container {
    box-shadow: none;
    border: thin solid ${color('black30')};
    border-radius: 6px;

    .rdw-editor-toolbar {
      background: ${color('white')};
      border-bottom: 1px solid ${color('black30')};
      border-radius: 6px 6px 0 0;
      margin: 0;
    }

    .rdw-editor-main {
      border-radius: 0 0 6px 6px;
      padding: 0;
    }
  }

  .rte-container:focus-within {
    border: thin solid ${color('accent-3')};
    outline: 0;
  }

  .public-DraftEditor-content {
    height: 17rem;
    overflow: auto;
    padding-left: 11px;
  }

  &[data-disabled='true'] .rte-container {
    border: thin solid ${color('dark-4')};
  }

  &[data-disabled='true'] .rdw-editor-main {
    background: ${color('black5')};
  }

  &[data-disabled='true'] .rdw-option-wrapper {
    cursor: pointer;
    pointer-events: none;
  }

  &[data-invalid='true'] .rte-container {
    border: thin solid ${color('critical100')};
  }
`;

export const Label = styled.label<{ show: boolean }>`
  display: ${p => (p.show ? 'block' : 'none')};

  @media (${p => p.theme.tabletPortraitAndUp}) {
    margin-top: 0.5rem;
    line-height: 1.5;
  }
`;

export const Help = styled.small`
  color: ${color('black60')};
  font-size: 0.875rem;
`;

export const Input = styled.div`
  ${RTEStyles}

  flex: 1;
  
  input[type='tel'],
  input[type='text'],
  input[type='email'],
  input[type='search'],
  input[type='number'],
  input[type='password'],
  textarea,
  button[class*='Select__StyledSelectDropButton'] {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75rem 1rem;
    border-radius: 6px;
    border: thin solid ${color('dark-4')};

    &::placeholder {
      color: ${color('dark-3')};
    }

    &:focus {
      border: thin solid ${p => p.theme.global.colors['accent-3']};
      outline: 0;
    }
  }
  
  input[disabled] {
    color: ${color('black100')};
    opacity: 1;
    -webkit-text-fill-color: ${color('black100')} ;
    
    &::-webkit-input-placeholder {
      color: ${color('dark-3')};
      opacity: 0.4;
    }
  }
  
  input:disabled {
    color: ${color('black100')};
    opacity: 1;
    -webkit-text-fill-color: ${color('black100')};
    
    &::-webkit-input-placeholder {
      color: ${color('dark-3')};
      opacity: 0.4;
    }
  }

  /*
   * Unfortunately the grommet select is not simply a styled input element
   * It is a button containing several divs and ultimately has an input nested inside
   * Because of this, we must style the button and not the input
   *
   * These styles remove the text input styles an make any grommet select specific changes we need
   */
  button[class*='Select__StyledSelectDropButton'] {
    /* This removes the default grommet disabled style with opacity: 0.3;
     * https://storybook.grommet.io/?path=/story/input-select-disabled--disabled */
    opacity: 1;
    padding: 0;

    /* This ensures our selects do not change height depending on if they have / do not have pills */
    min-height: 44px;

    input {
      border: 0 !important;
      background-color: transparent;
      min-height: 44px;
    }
  }

  &[data-disabled='true'] {
    input[type='tel'],
    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='number'],
    input[type='password'],
    textarea,
    input[type='checkbox'] + span,
    button[class*='Select__StyledSelectDropButton'] {
      background: ${color('black5')};
      border: thin solid ${color('dark-4')};
      cursor: pointer;
      pointer-events: none;
      color: ${color('black100')}
      opacity: 1 !important;
      -webkit-text-fill-color: ${color('black100')}
      
      &::-webkit-input-placeholder {
        color: ${color('dark-3')};
      }
    },
    color: ${color('black100')}
    opacity: 1;
    -webkit-text-fill-color: ${color('black100')}
    
    &::-webkit-input-placeholder {
      color: ${color('dark-3')};
    }
  }
  
  &[data-invalid='true'] {
    input[type='tel'],
    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='number'],
    input[type='password'],
    textarea,
    button[class*='Select__StyledSelectDropButton'] {
      background-color: ${p => p.theme.inputBackgroundInvalid};
      border: thin solid ${color('critical100')};
    }
  }
`;

// patches the `smth this is a required field` mashups of error
// messages when a+ cannot find the responsible field and presents
// errors as plain text
const ErrorText = ({ children, ...rest }: any) => {
  const text =
    typeof children === 'string'
      ? upperFirst(children).replace(/(^|, and |, | and )([a-z]+) this is/gm, '$1$2 is')
      : children;

  return <small {...rest}>{text}</small>;
};

export const ErrorBlock = styled(ErrorText)`
  color: ${color('critical100')};
  display: block;
  font-size: 0.875rem;
`;

export const StackedContainer = styled.div`
  & ~ & {
    margin-top: 2rem;
  }

  flex: 1;
  max-width: 100%;

  ${Label}, ${Input}, ${ErrorBlock} {
    margin-bottom: 0.375rem;
  }
`;

const randomStr = () => Math.random().toString(16).slice(2);

const randomId = () => `id-${randomStr()}`;

export const StackedLayout = (props: LayoutProps) => {
  const { className, label, error, help, input, value, disabled, id = randomId() } = props;
  const empty = value == null;

  // TODO get rid of this in favour of the data-empty attribute
  const classNames = [...(className ? [className] : []), ...(empty ? ['empty'] : [])].join(' ');

  return (
    <StackedContainer className={classNames}>
      <Label htmlFor={id} show={label != null}>
        {label}
      </Label>
      <Input data-disabled={disabled} data-invalid={!!error} data-empty={empty}>
        {React.cloneElement(input as any, { help: undefined, id })}
      </Input>
      {error ? <ErrorBlock>{error}</ErrorBlock> : null}
      {help ? <Help>{help}</Help> : null}
    </StackedContainer>
  );
};

// For use with @compono/ui Field components
// This layout passes down all props to the child component since labels are nested within
export const UILayout = (props: LayoutProps) => {
  const { input, ...rest } = props;

  return React.cloneElement(input as any, { ...rest });
};
