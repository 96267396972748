import { Select, SelectProps, SelectableOptionT } from '@compono/ui';
import React from 'react';
import PropTypes from 'prop-types';
import { field } from 'a-plus-forms';
import { StackedLayout } from './ReskinLayouts';

type Props = Omit<SelectProps, 'onChange' | 'value'> & {
  isIndeterminate?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  defaultValue?: string;
};

class SelectComponent extends React.Component<Props> {
  static contextTypes = {
    APFProps: PropTypes.object,
  };

  onChange = (selectedOptions: SelectableOptionT | SelectableOptionT[]) => {
    this.props.onChange?.((selectedOptions as SelectableOptionT)?.value);
  };

  render() {
    const { defaultValue, onChange, options, value, placeholder, ...rest } = this.props;
    const mappedOptions = options.map(option => ({
      ...option,
      ...(value ? { selected: value === option?.value } : {}),
    }));

    /**
     * @fix is to stop Cannot assign to read only property 'current' of object '#<Object>'
     * Basically because this project is still using the old version of @compono/ui there is an issue with the Select component and immer
     * This is a quick fix to get around it by using a native select instead of the compono one
     */
    return (
      <select onChange={e => onChange && onChange(e.target.value)} value={defaultValue || value}>
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {mappedOptions.map(option => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    );

    // Leaving old code here for reference incase we update @compono/ui later
    // return <Select options={mappedOptions} onChange={this.onChange} {...rest} />;
  }
}

export default field<Props>({ layout: StackedLayout })(SelectComponent);
