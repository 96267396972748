import * as React from 'react';
import { Form as AForm } from 'a-plus-forms';
import { useQuery } from '@apollo/react-hooks';
import { Button, CancelIcon } from '@shortlyster/ui-kit';
import {
  ErrorBlock,
  Textarea,
  TextInput,
  LocationSelect,
  SelectSingle,
} from '@shortlyster/forms-kit';
import { FormFooter } from 'src/components/Forms';
import history from 'lib/history';
import {
  OrgProduct,
  Organisation,
  OrganisationIndustry,
  OrganisationSize,
  productsQuery,
} from './queries';
import AppAccessPicker from './AppAccess';
import { omitNull } from '../../utils';

export interface FormProps {
  products?: OrgProduct[];
  onSubmit: (Organisation: Pick<Organisation, 'id' | 'products'>) => void;
  disabled?: boolean;
}

export default ({ products, onSubmit, sizes, industries, disabled }: FormProps) => {
  const {
    data: productsConfigs,
    loading,
    error,
  } = useQuery(productsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const validate = (input: Partial<Organisation>) => {
    let errors = {};
    if ((input.products || []).length < 1) {
      errors = { ...errors, products: 'At least one product is required' };
    }

    let hasLimitError = false;
    input.products?.forEach(product => {
      const config = productsConfigs.products.find(pc => pc.code === product.productCode);
      config.limits?.forEach((limit: string) => {
        const updatedLimit = product.limits?.find(l => l.limitCode === limit);
        if (!updatedLimit || updatedLimit.limit === undefined) {
          hasLimitError = true;
        }
      });
    });
    if (hasLimitError) {
      errors = { ...errors, products: 'Each limit must be set' };
    }

    return Object.entries(errors).length ? errors : undefined;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <ErrorBlock>{error}</ErrorBlock>;

  const handleSubmit = (data: unknown) => onSubmit(omitNull(data, true));

  return (
    <AForm
      onSubmit={handleSubmit}
      defaultValue={{ products: products?.map(omitNull) }}
      schema={validate}
    >
      <AppAccessPicker
        required
        name="products"
        label="Product access"
        key="products"
        subLabel="Select the product solutions that this organisation will have access to"
        products={productsConfigs.products}
      />
      <FormFooter>
        <Button disabled={disabled} type="submit">
          Save products
        </Button>
        <Button type="reset" icon={CancelIcon} buttonStyle="secondary" onClick={history.goBack}>
          Cancel
        </Button>
      </FormFooter>
    </AForm>
  );
};
