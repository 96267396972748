import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Section,
  Content,
  Button,
  CreateIcon,
  CancelIcon,
  TitleSection,
  H1,
  P,
  Strong,
} from '@shortlyster/ui-kit';
import Table from 'src/components/Table';
import history from 'lib/history';
import { ComponoUser, NonAdminsQueryResult, UpdateAdminAccessInput } from './types';
import { NonAdminsQuery, UpdateAdminAccess } from './queries';

const SearchInput = styled.input`
  width: 100%;
  height: 2.5rem;
  padding: 0.75rem;
  margin-bottom: 2rem;
`;

const CancelButton = styled(Button)`
  margin-top: 3rem;
  margin-bottom: 4rem;
`;

const NewAdminPage = () => {
  const [search, setSearch] = useState('');

  const { data, loading, refetch } = useQuery<NonAdminsQueryResult>(NonAdminsQuery, {
    variables: { search },
    fetchPolicy: 'cache-and-network',
  });

  const [updateAdminAccess] = useMutation<any, UpdateAdminAccessInput>(UpdateAdminAccess, {
    onCompleted: () => history.goBack(),
  });

  const { nonAdminUsers = [] } = data ?? {};

  const onSearch = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();

    setSearch(event.target.value);
    refetch();
  };

  const onAdd = (admin: ComponoUser) => {
    updateAdminAccess({
      variables: {
        userId: admin.id,
        isAdmin: true,
      },
    });
  };

  const results = loading ? 'Loading...' : `${nonAdminUsers?.length || 0} Results found`;

  return (
    <>
      <TitleSection>
        <H1>Add Admin</H1>
      </TitleSection>
      <Section>
        <Content>
          <P>
            Search for an employer account you wish to grant admin access to.
            <br />
            The account must have an @compono.com suffix.
          </P>
          <SearchInput type="text" onKeyPress={onSearch} />
          <P>
            <Strong>{results}</Strong>
          </P>
          {!loading && !!nonAdminUsers?.length && (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {nonAdminUsers.map((admin: ComponoUser) => (
                  <tr key={admin.id}>
                    <td>
                      {admin.firstname} {admin.lastname}
                    </td>
                    <td>{admin.email}</td>
                    <td>
                      <Button onClick={() => onAdd(admin)} icon={CreateIcon}>
                        Add admin
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <CancelButton
            type="reset"
            icon={CancelIcon}
            buttonStyle="secondary"
            onClick={history.goBack}
          >
            Cancel
          </CancelButton>
        </Content>
      </Section>
    </>
  );
};

export default NewAdminPage;
