import React, { FC } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';

import { Toaster, UIProvider } from '@compono/ui';
import { theme } from '@compono/ui-theme-compono';
import {
  App,
  Article,
  GlobalStyles,
  GlobalStylesNoFont,
  Header,
  Main,
  ThemeProvider,
} from '@shortlyster/ui-kit';
import { HEADLESS_TESTING } from 'lib/env';
import { AuthFetcher } from './lib/auth/AuthFetcher';
import { Auth0Wrapper } from './lib/auth/Auth0Wrapper';
import AuthorizedApolloProvider from './lib/auth/AuthorizedApolloProvider';
import StyleOverrides from './styles';
import { formTheme } from './components/Forms/form_styles';
import Navigation from './components/Navigation';
import Router from './Routes';

const AppStyles = HEADLESS_TESTING ? GlobalStylesNoFont : GlobalStyles;

function AppInner() {
  const history = useHistory();
  return (
    <ThemeProvider theme={formTheme}>
      <UIProvider theme={theme} history={history}>
        <Auth0Wrapper>
          <AuthorizedApolloProvider>
            <AuthFetcher>
              <App>
                <AppStyles />
                <StyleOverrides />
                <Header>
                  <Navigation />
                </Header>
                <Main>
                  <Article>
                    <Toaster
                      position={'top-center'}
                      reverseOrder={true}
                      containerStyle={{ top: '120px' }}
                    />
                    <Router />
                  </Article>
                </Main>
              </App>
            </AuthFetcher>
          </AuthorizedApolloProvider>
        </Auth0Wrapper>
      </UIProvider>
    </ThemeProvider>
  );
}

const AppRoot: FC = () => (
  <BrowserRouter>
    <AppInner />
  </BrowserRouter>
);

export default AppRoot;
