import * as React from 'react';
import AuthorName from 'src/components/Name';
import { Button, EditIcon } from '@shortlyster/ui-kit';
import SimpleDate from 'src/components/Date';
import Table from 'src/components/Table';
import Link from '../../components/Link';
import { Qualification } from './types';

interface Props {
  qualifications: Qualification[];
  status: string;
}

export default class QualificationsList extends React.Component<Props> {
  render() {
    const { qualifications = [], status } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>Date {status === 'draft' ? 'submitted' : status}</th>
            <th>Qualification name</th>
            <th>Submitted by</th>
            <th>User type</th>
            <th>Modify</th>
          </tr>
        </thead>
        <tbody>
          {qualifications.map((qualification, index) => {
            let { author } = qualification;
            if (!author) {
              author = { id: '', firstname: 'UNKNOWN', lastname: 'AUTHOR', type: 'employer' };
            }
            return (
              <tr key={`qualification-${index}`}>
                <td>
                  <SimpleDate
                    date={status === 'draft' ? qualification.createdAt : qualification.updatedAt}
                  />
                </td>
                <td>{qualification.name}</td>
                <td>
                  <AuthorName author={author} />
                </td>
                <td style={{ textTransform: 'capitalize' }}>{author.type}</td>
                <td>
                  <Link to={`/qualifications/${qualification.id}`}>
                    <Button icon={EditIcon}>Edit</Button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }
}
