import { EmailIcon, SubmenuLink } from '@shortlyster/ui-kit';
import { useMutation } from '@apollo/react-hooks';
import { OrgAccess } from './types';
import { acceptInviteMutation, AcceptInviteInput, AcceptInviteOutput } from './queries';

type Props = {
  userId?: string;
  orgAccess?: OrgAccess;
  onAcceptInvite: (orgAccesses: OrgAccess[]) => Promise<void>;
};

const AcceptInvite = ({ orgAccess, userId, onAcceptInvite }: Props) => {

  const organisationId = orgAccess?.organisation?.id || orgAccess?.organisationId;

  const [acceptInvite, { loading: acceptInviteLoading }] = useMutation<AcceptInviteOutput, AcceptInviteInput>(
    acceptInviteMutation
  );

  const status  = orgAccess?.invitation?.status;

  if (organisationId && userId && status === 'pending')
  return (
    <SubmenuLink
      disabled={acceptInviteLoading}
      icon={EmailIcon}
      onClick={() => {
        acceptInvite({ variables: { userId, organisationId } })
        .then((result) => {
          if(result.data) {
            onAcceptInvite(result.data?.acceptInvite.orgAccesses);
          }
        })
      }}
    >
      Accept invite
    </SubmenuLink>
  );
  return <></>
};

export default AcceptInvite;
