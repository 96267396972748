import * as React from 'react';
import { EditIcon, Button } from '@shortlyster/ui-kit';
import Table from 'src/components/Table';
import Link from '../../components/Link';
import { Organisation } from './queries';

interface Props {
  organisations: Organisation[];
}

const OrganisationsList = ({ organisations }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Modify</th>
      </tr>
    </thead>
    <tbody>
      {organisations.map((organisation, index) => {
        return (
          <tr key={`org-${index}`}>
            <td>{organisation.name}</td>
            <td>
              <Link to={`/organisations/${organisation.id}`}>
                <Button icon={EditIcon}>Edit</Button>
              </Link>
            </td>
          </tr>
        );
      })}
    </tbody>
  </Table>
);

export default OrganisationsList;
