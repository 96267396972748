import React, { FC, useState } from 'react';
import { createErrorToast, createSuccessToast } from 'components/Toast';
import { SkillDialog, SkillNameInput, SkillStatusInput } from './Components';
import { SkillService } from './SkillService';

interface CreateSkillDialogProps {
  closeDialog: () => void;
}

const CreateSkillDialog: FC<CreateSkillDialogProps> = ({ closeDialog }) => {
  const [clicked, setClicked] = useState<boolean>(false);
  const [newSkill, setSkill] = useState({ name: '', status: 'draft' });
  const [createSkill, { loading }] = SkillService.useCreateSkill({
    refetchQueries: ['Skills'],
  });

  const onSubmit = async () => {
    setClicked(true);
    if (!newSkill.name.trim()) return;
    try {
      await createSkill({
        variables: {
          name: newSkill.name,
          status: newSkill.status,
          source: newSkill.source,
        },
      });

      closeDialog();

      createSuccessToast({
        title: 'Skill name added',
        message: `Skill "${newSkill.name}" has been successfully added.`,
      });
    } catch {
      createErrorToast({
        title: 'Error',
        message: `Something went wrong creating the skill, please try again later.`,
      });
    }
  };

  return (
    <SkillDialog
      title="Create Skill"
      data-testid="create-skill-dialog"
      disabled={loading}
      onClose={closeDialog}
      onSubmit={onSubmit}
    >
      <SkillNameInput
        value={newSkill?.name}
        showError={clicked}
        onChange={name => {
          setSkill({
            ...newSkill,
            name,
          });
        }}
        onPressEnter={onSubmit}
      />
      <SkillStatusInput
        value={newSkill?.status}
        isNewSkill={true}
        onChange={status => {
          setSkill({
            ...newSkill,
            status,
          });
        }}
      />
    </SkillDialog>
  );
};

export default CreateSkillDialog;
