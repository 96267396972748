import * as React from 'react';
import { Select } from '@shortlyster/forms-kit';

export type Status = 'draft' | 'approved' | 'rejected';

interface Props {
  onChange?: (value: Status) => void;
  value: string;
}

const options = [
  { value: 'draft', label: 'Draft' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];

export default class StatusSelect extends React.Component<Props> {
  render() {
    return <Select label="View" options={options} {...this.props} />;
  }
}
