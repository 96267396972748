import React, { useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';

import { Error as ValidationError } from 'a-plus-forms';
import gql from 'graphql-tag';
import history from 'lib/history';
import { Section, Content, TitleSection, Breadcrumb, H1, Subtitle2 } from '@shortlyster/ui-kit';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { LegacyPage } from 'components/LegacyPage';
import { Employer } from './types';
import EmployerForm from './EmployerForm';
import DisableToggle from './DisableToggle';
import Link from '../../components/Link';
import getCustomerSubtitle from '../../lib/getCustomerSubtitle';
import useOrgAccess from './access/useOrgAccess';
import { EmployerPageType } from './access/orgAccessContext';

interface FetchEmployer {
  employers: Employer[];
}

export const fetchQuery = gql`
  query FetchEmployer($id: ID!) {
    employers(id: $id) {
      id
      disabled
      firstname
      lastname
      email
      orgAccesses {
        organisationId
        organisation {
          metadata {
            source
          }
        }
        deactivated
        apps {
          appCode
          roles {
            value
            displayName
          }
        }
        invitation {
          status
        }
      }
      location {
        country
        state
        city
      }
      employeeNumber
      phoneWork
      phoneMobile
    }
    organisations(id: $id) {
      metadata {
        source
      }
    }
  }
`;

interface UpdateEmployer {
  updateEmployer: {
    id: string;
  };
}

const updateQuery = gql`
  mutation UpdateEmployer(
    $id: ID!
    $firstname: String!
    $lastname: String!
    $email: String!
    $location: LocationInput
    $orgAccesses: [OrgAccessInput]!
    $employeeNumber: String
    $phoneWork: String
    $phoneMobile: String
  ) {
    updateEmployer(
      id: $id
      firstname: $firstname
      lastname: $lastname
      email: $email
      location: $location
      orgAccesses: $orgAccesses
      employeeNumber: $employeeNumber
      phoneWork: $phoneWork
      phoneMobile: $phoneMobile
    ) {
      id
    }
  }
`;

const UpdateEmployerPage = () => {
  const { id } = useParams();
  const { data: employersData, loading } = useQuery<FetchEmployer>(fetchQuery, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const [updateEmployer, { loading: updating }] = useMutation<UpdateEmployer>(updateQuery);
  const employer = employersData?.employers?.[0];
  // NOTE: Newscorp allows only one org
  const isNews = employer?.orgAccesses?.[0].organisation?.metadata?.source === 'newscorp';

  if (employer) {
    const { orgAccesses = [], location = {} } = employer;
    const cleanup = (object: any, ...goodKeys: string[]): void =>
      Object.keys(object || {}).forEach(key => {
        if (!goodKeys.includes(key)) {
          delete object[key];
        }
      });
    cleanup(location, 'country', 'state', 'city');
    orgAccesses?.forEach(orgAccess => {
      cleanup(orgAccess, 'organisationId', 'deactivated', 'invitation', 'apps');
      orgAccess?.apps?.forEach(app => {
        cleanup(app, 'appCode', 'roles');
        app.roles?.forEach(role => cleanup(role, 'displayName', 'value'));
      });
    });

    Object.keys(employer).forEach(key => {
      if ((employer as any)[key] === null) {
        delete (employer as any)[key];
      }
    });
  }

  const [{ orgAccesses }, dispatch] = useOrgAccess();

  useEffect(() => {
    dispatch({
      type: 'setEmployerPageType',
      payload: EmployerPageType.EDIT,
    });
  }, []);

  useEffect(() => {
    if (!employer?.id) return;
    dispatch({
      type: 'setOrgAccesses',
      payload: employer?.orgAccesses,
    });
  }, [employer?.id]);

  const onSubmit = async (newData: Employer): Promise<void> => {
    if (isEmpty(orgAccesses)) {
      throw new ValidationError({ orgAccesses: 'this is a required field' });
    }
    const variables = {
      ...newData,
      id: employer?.id,
      orgAccesses: orgAccesses?.map(orgAccess => {
        const { organisationId, deactivated } = orgAccess;
        return {
          organisationId,
          deactivated: deactivated ? true : undefined,
          apps: orgAccess?.apps?.map(app => ({
            ...app,
            roles: app.roles?.map(role => role.value),
          })),
        };
      }),
    };
    await updateEmployer({ variables });

    dispatch({
      type: 'reset',
    });
    history.goBack();
  };

  const name = loading ? 'Loading...' : `${employer?.firstname} ${employer?.lastname}`;

  return (
    <LegacyPage>
      <TitleSection>
        <Breadcrumb>
          <Link to="/employers">Employers</Link>
          <Link to="" aria-current={true}>
            {name}
          </Link>
        </Breadcrumb>
        <H1>{name}</H1>
        <Subtitle2>{getCustomerSubtitle(loading, isNews)}</Subtitle2>
      </TitleSection>
      <Section>
        <Content>
          {loading && <p>Loading...</p>}
          {!loading && !employer && <p>No employer found...</p>}
          {!loading && employer && (
            <>
              <DisableToggle id={employer.id} disabled={employer.disabled} />
              <EmployerForm onSubmit={onSubmit} employer={employer} disabled={updating} />
            </>
          )}
        </Content>
      </Section>
    </LegacyPage>
  );
};

export default UpdateEmployerPage;
