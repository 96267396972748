import styled from 'styled-components';
import React, { useState } from 'react';
import Checkbox from 'src/components/Forms/Checkbox';
import { field, InputProps } from 'a-plus-forms';
import { EmptyLayout, LabelBlock, InputBlock } from '@shortlyster/forms-kit';
import { Box, Blockquote } from '@compono/ui';
import HRIntegrationFields from './HRIntegrationFields';
import { Integration, FieldConfiguration } from './queries';

export const InfoBlock = styled.small`
  opacity: 0.5;
  display: block;
`;

const CheckboxLabelAligned = styled(Checkbox)`
  && ${LabelBlock} {
    display: none;
  }

  ${InputBlock} label {
    align-items: center;
    display: flex;
    line-height: inherit;
    margin-top: 0;
  }

  ${InputBlock} span {
    text-transform: capitalize;
    flex: 1;
    margin-left: calc(${p => p.theme.spacingFormLayout} / 2);
  }
`;

type Props = InputProps & {
  disabled?: boolean;
  value: Integration;
  onChange?: (integrations: Integration) => void;
};

const HrisIntegrationComponent = ({ value, disabled, onChange }: Props) => {
  const integration: Integration = value;
  const [fieldValues, setFieldValues] = useState<FieldConfiguration[]>(integration.fields ?? []);

  const addOrRemove = (checked: boolean) => {
    if (integration.enabled !== checked) {
      if (onChange) {
        const { enabled, ...rest } = integration;
        onChange({
          enabled: checked,
          ...rest,
        });
      }
    }
  };

  const fieldsChange = (updatedFields: FieldConfiguration[]) => {
    if (onChange) {
      const { fields: _, ...rest } = integration;
      const updatedIntegration: Integration = {
        fields: updatedFields,
        ...rest,
      };
      setFieldValues(updatedFields);
      onChange(updatedIntegration);
    }
  };

  return (
    <Box>
      <CheckboxLabelAligned
        key={integration.name}
        onChange={(checked: boolean) => addOrRemove(checked)}
        defaultValue={integration.enabled}
        label={integration.displayName}
      />
      {!!fieldValues && fieldValues.length > 0 && integration.enabled && (
        <Blockquote>
          <HRIntegrationFields
            name="fields"
            onChange={fieldsChange}
            value={fieldValues}
            disabled={disabled}
          />
        </Blockquote>
      )}
    </Box>
  );
};

export default field<Props>({ layout: EmptyLayout, nested: true })(HrisIntegrationComponent);
