import React, { ReactNode } from 'react';
import { Alert, createToast, Text } from '@compono/ui';

interface CreateToastParams {
  title: string;
  message: ReactNode;
}

export const createErrorToast = ({ title, message }: CreateToastParams) =>
  createToast(
    <Alert tone="critical" title={title}>
      <Text data-testid="alert-message">{message}</Text>
    </Alert>,
    { duration: 3000 }
  );

export const createSuccessToast = ({ title, message }: CreateToastParams) =>
  createToast(
    <Alert tone="success" title={title}>
      <Text data-testid="alert-message">{message}</Text>
    </Alert>,
    { duration: 3000 }
  );
