import gql from 'graphql-tag';

export interface FieldConfiguration {
  key: string;
  displayName: string;
  enabled: boolean;
  required: boolean;
  fieldType: string;
  defaultSelected?: boolean;
}

export interface Integration {
  name: string;
  displayName: string;
  enabled: boolean;
  fields?: FieldConfiguration[];
}
export interface Integrations {
  hris: Integration[];
}

export interface OrganisationIntegrationsQuery {
  integrations: Integrations;
}

export const getIntegrationsQuery = gql`
  query OrganisationIntegrationsQuery($id: ID!) {
    integrations(id: $id) {
      hris {
        name
        displayName
        enabled
        fields {
          key
          defaultSelected
          displayName
          fieldType
          enabled
          required
        }
      }
    }
  }
`;

export interface UpdateHrisIntegrationMutation {
  updateHrisIntegration: boolean;
}

export interface IntegrationInput {
  enabled: boolean;
  name: string;
  fields?: string[];
}

export interface UpdateHrisIntegrationInput {
  id: string;
  values: IntegrationInput[];
}

export interface UpdateHrisIntegrationMutationInput {
  variables: UpdateHrisIntegrationInput;
}

export const updateHrisIntegrationMutation = gql`
  mutation UpdateHrisIntegration($id: ID!, $values: [IntegrationInput!]!) {
    updateHrisIntegration(id: $id, values: $values)
  }
`;
