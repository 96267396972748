import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* FIXME button borders are cut off by wrapping ui-kit components https://app.clickup.com/t/23mzad */
  section {
    overflow: initial !important;
  }

  form {
    margin-bottom: 4rem;
  }
`;
