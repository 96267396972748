import { Flex, SearchInput } from '@compono/ui';
import { FC } from 'react';
import * as React from 'react';

interface SkillsNameSearchBarProps {
  search?: string;
  onSearchChange?: (value: string) => void;
}

export const SkillsNameSearchBar: FC<SkillsNameSearchBarProps> = ({ search, onSearchChange }) => (
  <Flex sx={{ gap: 5, flexWrap: ['wrap', 'nowrap'], width: '100%', maxWidth: '50%' }}>
    <SearchInput
      placeholder={'Search skill name'}
      value={search}
      onChange={onSearchChange}
      sx={{ width: 'auto', flex: 1 }}
    />
  </Flex>
);
