type Name = {
  firstName: string;
  lastName: string;
};

export const titleCase = (text: string): string =>
  text
    .split(' ')
    .filter(Boolean)
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');

export const formatName = ({ firstName, lastName }: Name) => titleCase(`${firstName} ${lastName}`);

/** capitalizeFirstLetter
 * to capitalize the first letter of each word
 */
export const upperFirst = (text?: string) => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};
