import React, { FC } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

import { A, anchorStyle } from '@shortlyster/ui-kit';

// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative/19709846#comment101754558_19709846
const externalURL = new RegExp('^(//|[a-z]+:)', 'i');
export const isInternalURL = (url: string): boolean => !externalURL.test(url);

const RRLink = styled(ReactRouterLink)`
  ${anchorStyle}
  div {
    pointer-events: bounding-box;
  }
`;

export type Props = {
  target?: string;
  to?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const Link: FC<Props> = ({ to, children, ...props }) => {
  if (to && isInternalURL(to)) {
    return (
      <RRLink to={to} {...props}>
        {children}
      </RRLink>
    );
  }

  return (
    <A href={to} {...props}>
      {children}
    </A>
  );
};

export default styled(Link)``;
