import * as React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Section, TitleSection, Breadcrumb, H1, Subtitle2 } from '@shortlyster/ui-kit';
import { useParams, useHistory } from 'react-router-dom';
import { LegacyPage } from 'components/LegacyPage';
import {
  OrganisationIndustriesQueryResult,
  organisationQuery,
  OrganisationQuery,
  OrganisationsIndustriesQuery,
  OrganisationSizesQueryResult,
  OrganisationsSizesQuery,
  UpdateOrganisationMutation,
  updateOrganisationMutation,
} from '../queries';
import OrganisationForm, { NewOrganisation } from '../OrganisationForm';
import Link from '../../../components/Link';
import getCustomerSubtitle from '../../../lib/getCustomerSubtitle';
import OrganisationNavBar from './OrganisationNavBar';
import { clearTypeName } from '../../../utils';

const UpdateOrganisationPage: React.FC = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading: loadingOrganisation, data: organisationQueryResponse } =
    useQuery<OrganisationQuery>(organisationQuery, {
      variables: { id },
      fetchPolicy: 'cache-and-network',
    });

  const { loading: loadingOrgSizes, data: orgSizesQueryResponse } =
    useQuery<OrganisationSizesQueryResult>(OrganisationsSizesQuery, {
      fetchPolicy: 'cache-and-network',
    });
  const { loading: loadingOrgIndustries, data: orgIndustriesQueryResponse } =
    useQuery<OrganisationIndustriesQueryResult>(OrganisationsIndustriesQuery, {
      fetchPolicy: 'cache-and-network',
    });
  const [updateOrganisation, { loading: updatingOrganisation }] =
    useMutation<UpdateOrganisationMutation>(updateOrganisationMutation);

  if (loadingOrganisation || loadingOrgSizes || loadingOrgIndustries) return <p>Loading...</p>;
  if (!organisationQueryResponse) return <p>Organisation not found...</p>;

  const organisation = clearTypeName(organisationQueryResponse.organisations[0]);

  const isNews = organisation?.metadata?.source === 'newscorp';

  const onSubmit = async ({ name, description, sizeId, industryId, location }: NewOrganisation) => {
    const variables = {
      organisation: {
        name,
        description,
        sizeId,
        industryId,
        location,
      },
      id: organisationQueryResponse.organisations[0].id,
    };
    await updateOrganisation({
      variables,
      refetchQueries: ['OrganisationsQuery'],
    });
  };

  return (
    <LegacyPage>
      <TitleSection>
        <Breadcrumb>
          <Link to="/organisations">Organisations</Link>
          <Link to="" aria-current={true}>
            {organisation.name}
          </Link>
        </Breadcrumb>
        <H1>{organisation.name}</H1>
        <Subtitle2>{getCustomerSubtitle(loadingOrganisation, isNews)}</Subtitle2>
      </TitleSection>
      <OrganisationNavBar organisationId={id as string} active="details" />
      <Section>
        {orgSizesQueryResponse && orgIndustriesQueryResponse && (
          <OrganisationForm
            onSubmit={onSubmit}
            organisation={organisation}
            sizes={orgSizesQueryResponse.organisationSizes}
            industries={orgIndustriesQueryResponse.organisationIndustries}
            disabled={updatingOrganisation}
          />
        )}
      </Section>
    </LegacyPage>
  );
};

export default UpdateOrganisationPage;
