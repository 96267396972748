import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Section, Button, CreateIcon, Pagination, TitleSection, H1 } from '@shortlyster/ui-kit';
import useQueryParams from 'src/lib/useQueryParams';
import { PageCounts } from 'src/types/PageCounts';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Employer } from './types';
import EmployersList from './EmployersList';
import SearchBar from '../../components/SearchBar';

import Link from '../../components/Link';

const SearchBarHint = styled.p`
  font-size: 0.8rem;
`;

const AsideRight = styled.div`
  width: 100%;
  text-align: end;
`;

const FoundResult = styled.p`
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  padding-top: 3px;
  height: 32px;
  margin: 10px 0 10px 0;
  & > div > svg {
    margin-bottom: 1px;
  }
`;
interface EmployersQuery {
  employers?: Employer[];
  employersCount?: PageCounts;
}

export const query = gql`
  query RequitersQuery($page: Int!, $search: String) {
    employers(page: $page, search: $search) {
      id
      email
      lastname
      firstname
      createdAt
    }
    employersCount(search: $search) {
      pages
      total
    }
  }
`;

const EmployersPage = () => {
  const [searchInput, setSearchInput] = useState<String>('');
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const history = useHistory();
  const location = useLocation();
  const search = queryParams.get('search') ?? '';

  const { data: employerData, loading } = useQuery<EmployersQuery>(query, {
    variables: search ? { page, search } : { page },
    fetchPolicy: 'cache-and-network',
  });
  const { employers = [], employersCount } = employerData ?? {};

  const { pages = 1, total } = employersCount ?? {};

  const sortedEmployers = [...employers].sort((a, b) =>
    (a.firstname || '').toLowerCase() >= (b.firstname || '').toLowerCase() ? 1 : -1
  );

  const onSubmit = () => {
    const url = searchInput ? `/employers?search=${searchInput}` : `/employers`;
    history.push(url);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  return (
    <>
      <TitleSection>
        <H1>Employers</H1>
      </TitleSection>
      <Section>
        <AsideRight>
          <Link to="/employers/new">
            <StyledButton icon={CreateIcon}>Create Employer</StyledButton>
          </Link>
        </AsideRight>
        <SearchBarHint>Search for an employer</SearchBarHint>
        <SearchBar
          onSubmit={onSubmit}
          onChange={onChange}
          search={search}
          placeholder={'search employers'}
        />
        <FoundResult>{`${total} results found`}</FoundResult>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <EmployersList employers={sortedEmployers} />
            <Pagination link={Link} page={page} pages={pages} location={location.search} />
          </>
        )}
      </Section>
    </>
  );
};

export default EmployersPage;
