import * as React from 'react';
import { Button, CancelIcon } from '@shortlyster/ui-kit';
import { Form, TextInput, Radios, FormFooter } from 'src/components/Forms';
import history from 'lib/history';
import QualificationSubcategorySelect from './SubcategorySelect';
import QualificationTypeSelect from './TypeSelect';
import { FormProps } from './types';

const schema = require('./schema.json');

const options = [
  { value: 'draft', label: 'Draft' },
  { value: 'approved', label: 'Approved' },
  { value: 'rejected', label: 'Rejected' },
];

export default ({ qualification, onSubmit, disabled }: FormProps) => (
  <Form onSubmit={onSubmit} defaultValue={qualification} schema={schema}>
    <TextInput
      name="name"
      label="Qualification name"
      placeholder="eg. Bachelor of Digging Holes"
      required
    />
    <QualificationTypeSelect label="Type" name="typeId" required />
    <QualificationSubcategorySelect label="Subcategory" name="subCategoryIds" required />
    <Radios name="status" options={options} label="Status" required />
    <FormFooter>
      <Button type="submit" disabled={disabled}>
        Save qualification
      </Button>
      <Button
        type="reset"
        icon={CancelIcon}
        buttonStyle="secondary"
        onClick={history.goBack}
        disabled={disabled}
      >
        Cancel
      </Button>
    </FormFooter>
  </Form>
);
