import { Box, Grid, Text } from '@compono/ui';
import React, { FC } from 'react';
import SimpleDate from 'src/components/Date';
import { upperFirst } from 'src/lib/formattingHelpers';
import { Skill } from '../types';

interface SkillDetailsProps {
  skill: Skill;
  includeStatus?: boolean;
}

const SkillDetails: FC<SkillDetailsProps> = ({ skill, includeStatus = false }) => (
  <Grid
    sx={{
      alignContent: 'center',
      gridTemplateColumns: ['1fr', '1fr 1fr'],
      marginTop: 5,
      gap: 5,
    }}
  >
    <Box data-testid="candidate-application-stage" sx={{ gridColumnStart: 1 }}>
      <Text font="sm" noCrop sx={{ color: 'black.60' }}>
        Skill source
      </Text>
      <Text font="md" noCrop>
        {upperFirst(skill.source)}
      </Text>
    </Box>
    {includeStatus && (
      <Box data-testid="candidate-application-stage">
        <Text font="sm" noCrop sx={{ color: 'black.60' }}>
          Status
        </Text>
        <Text font="md" noCrop>
          {upperFirst(skill.status)}
        </Text>
      </Box>
    )}
    <Box data-testid="candidate-application-stage" sx={{ gridColumnStart: 1 }}>
      <Text font="sm" noCrop sx={{ color: 'black.60' }}>
        Requested date
      </Text>
      <Text font="md" noCrop>
        <SimpleDate date={skill.createdAt} />
      </Text>
    </Box>
    <Box data-testid="candidate-application-stage">
      <Text font="sm" noCrop sx={{ color: 'black.60' }}>
        Requestor
      </Text>
      <Text font="md" noCrop>
        {skill.author ? `${skill.author.firstname} ${skill.author.lastname}` : 'Unknown'}
      </Text>
    </Box>
  </Grid>
);

export default SkillDetails;
