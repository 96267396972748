import * as React from 'react';

interface Props {
  date?: string | Date | number;
}

const parseDate = (value: any): number => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    return Date.parse(value) || Date.now();
  }
  if (value instanceof Date) {
    return value.getTime();
  }
  return Date.now();
};

const SimpleDate = ({ date }: Props) => {
  const parsedDate = parseDate(date);
  const formattedDate = new Date(parsedDate).toISOString().slice(0, 10).replace(/-/g, '/');

  return <>{formattedDate}</>;
};

export default SimpleDate;
