import React from 'react';
import { Box, FormField, Label } from '@compono/ui';

import { field, InputProps } from 'a-plus-forms';
import { EmptyLayout } from '@shortlyster/forms-kit';
import HrisIntegration from './HrisIntegration';
import { Integration } from './queries';

type Props = InputProps & {
  value: Integration[];
  onChange?: (integrations: Integration[]) => void;
  disabled?: boolean;
};

const HrisIntegrations = ({ value, disabled, onChange }: Props) => {
  const integrations = value;

  const hrisIntegrationOnChange = (updated: Integration) => {
    if (onChange) {
      const updateValues = integrations.map((integration: Integration) => {
        if (updated.name === integration.name) {
          return updated;
        }
        return integration;
      });
      onChange(updateValues);
    }
  };

  return (
    <Box>
      <FormField fieldId="hris" label="HRIS Integrations" size="sm">
        <Label>Select the HRIS integration that this organisation will have access to</Label>
        {integrations?.map((integration: Integration) => (
          <HrisIntegration
            disabled={disabled}
            key={integration.name}
            value={integration}
            onChange={hrisIntegrationOnChange}
          />
        ))}
      </FormField>
    </Box>
  );
};

export default field<Props>({ layout: EmptyLayout })(HrisIntegrations);
