import React, { FC, useState } from 'react';
import SimpleDate from 'src/components/Date';
import { formatName, upperFirst } from 'src/lib/formattingHelpers';
import {
  TableCell,
  HeaderCell,
  StandardTable,
  StandardTableColumn,
  MenuList,
  Menu,
  MenuItem,
  TableActionButton,
} from '@compono/ui';

import EditSkillDialog from 'features/Skills/EditSkillDialog';
import ApproveSkillDialog from 'features/Skills/ApproveSkillDialog';
import RejectSkillDialog from 'features/Skills/RejectSkillDialog';
import { SkillService } from 'features/Skills/SkillService';
import { createErrorToast, createSuccessToast } from 'components/Toast';
import { Skill } from './types';

interface ActionCellProps {
  skill: Skill;
  onEdit: (skill: Skill) => void;
  onApprove: (skill: Skill) => void;
  onReject: (skill: Skill) => void;
}

const ActionCell: FC<ActionCellProps> = ({ skill, onEdit, onApprove, onReject }) => (
  <Menu>
    <TableActionButton />
    <MenuList>
      <MenuItem
        data-testid="edit"
        onClick={() => {
          onEdit(skill);
        }}
      >
        Edit
      </MenuItem>
      {(skill.status === 'draft' || skill.status === 'rejected') && (
        <MenuItem
          data-testid="approve"
          onClick={() => {
            onApprove(skill);
          }}
        >
          Approve
        </MenuItem>
      )}
      {/* Compono skills can't be rejected until skill groups have been migrated to mongo */}
      {(skill.status === 'draft' || skill.status === 'approved') && skill.source !== 'compono' && (
        <MenuItem
          data-testid="reject"
          onClick={() => {
            onReject(skill);
          }}
        >
          Reject
        </MenuItem>
      )}
    </MenuList>
  </Menu>
);

enum ActionType {
  Edit,
  Approve,
  Reject,
}

interface DialogState {
  type: ActionType;
  skill: Skill;
}

interface Props {
  skills: Skill[];
}

const SkillsList: FC<Props> = ({ skills }) => {
  const [state, setState] = useState<DialogState>();
  const [approveSkill] = SkillService.useApproveSkill({
    refetchQueries: ['Skills'],
  });
  const [rejectSkill] = SkillService.useRejectSkill({
    refetchQueries: ['Skills'],
  });

  const closeDialog = () => {
    setState(undefined);
  };

  const handleOnEdit = (skill: Skill) => {
    setState({ skill, type: ActionType.Edit });
  };

  const handleOnApprove = async (skill: Skill) => {
    if (skill.status === 'draft') {
      try {
        await approveSkill({
          variables: {
            id: skill.id,
            name: skill.name,
          },
        });

        createSuccessToast({
          title: 'Skill status updated',
          message: `Skill "${skill.name}" has been successfully approved.`,
        });
      } catch {
        createErrorToast({
          title: 'Error',
          message: `Something went wrong updating the skill, please try again later.`,
        });
      }

      return;
    }

    setState({ skill, type: ActionType.Approve });
  };

  const handleOnReject = async (skill: Skill) => {
    if (skill.status === 'draft') {
      try {
        await rejectSkill({
          variables: {
            id: skill.id,
            name: skill.name,
          },
        });

        createSuccessToast({
          title: 'Skill status updated',
          message: `Skill "${skill.name}" has been successfully rejected.`,
        });
      } catch {
        createErrorToast({
          title: 'Error',
          message: `Something went wrong updating the skill, please try again later.`,
        });
      }

      return;
    }

    setState({ skill, type: ActionType.Reject });
  };

  // define the table columns
  const columns: StandardTableColumn[] = [
    {
      id: 'skillName',
      Header: () => HeaderCell({ label: 'Skill name' }),
      Cell: ({ rowData: { name } }) => <TableCell>{name}</TableCell>,
      width: 'minmax(200px, 2fr)',
    },
    {
      id: 'requestedBy',
      Header: () => HeaderCell({ label: 'Requested by' }),
      Cell: ({ rowData: { author } }) => (
        <TableCell>
          {author
            ? formatName({ firstName: author.firstname, lastName: author.lastname })
            : 'Unknown'}
        </TableCell>
      ),
      width: 'minmax(200px, 1fr)',
    },
    {
      id: 'requestedDate',
      Header: () => HeaderCell({ label: 'Requested date' }),
      Cell: ({ rowData }) => <SimpleDate date={rowData.createdAt} />,
      width: 'minmax(100px, 1fr)',
    },
    {
      id: 'skillSource',
      Header: () => HeaderCell({ label: 'Skill source' }),
      Cell: ({ rowData: { source } }) => <TableCell>{upperFirst(source)}</TableCell>,
      width: 'minmax(100px, 1fr)',
    },
    {
      id: 'skillStatus',
      Header: () => HeaderCell({ label: 'Skill status' }),
      Cell: ({ rowData: { status } }) => <TableCell>{upperFirst(status)}</TableCell>,
      width: 'minmax(100px, 1fr)',
    },
    {
      id: 'action',
      Header: () => HeaderCell({ label: 'Actions' }),
      Cell: ({ rowData }) => (
        <ActionCell
          skill={rowData as Skill}
          onEdit={handleOnEdit}
          onApprove={handleOnApprove}
          onReject={handleOnReject}
        />
      ),
      width: '100px',
    },
  ];

  const renderDialog = () => {
    if (state && state.skill) {
      switch (state.type) {
        case ActionType.Edit:
          return <EditSkillDialog skill={state.skill} closeDialog={closeDialog} />;
        case ActionType.Approve:
          return <ApproveSkillDialog skill={state.skill} closeDialog={closeDialog} />;
        case ActionType.Reject:
          return <RejectSkillDialog skill={state.skill} closeDialog={closeDialog} />;
        default:
          return null;
      }
    }

    return null;
  };

  return (
    <>
      <StandardTable
        data={skills}
        columns={columns}
        sx={{
          'thead::before': { right: 0 },
          thead: { zIndex: 0 },
          overflowWrap: 'break-word',
          overflow: 'auto',
          th: { color: 'black.100' },
          tr: { overflow: 'visible' },
        }}
      />
      {renderDialog()}
    </>
  );
};

export default SkillsList;
