import { Button, Flex } from '@compono/ui';
import React, { FC, useState } from 'react';
import useQueryParams from 'src/lib/useQueryParams';

import styled from 'styled-components';
import { HeaderBar as BaseHeaderBar } from '@shortlyster/ui-kit';
import { SkillsNameSearchBar } from './SkillsNameSearchBar';
import CreateSkillDialog from './CreateSkillDialog';

const HeaderBar = styled(BaseHeaderBar)`
  display: flex;
  flex-direction: column;
`;

type Props = {
  updateQuery: (key: string, value?: string) => void;
};

export const CreateSkillHeaderBar: FC<Props> = ({ updateQuery }) => {
  const queryParams = useQueryParams();

  const search = queryParams.get('search') || undefined;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <HeaderBar>
      <Flex
        sx={{
          gap: 5,
          flexWrap: ['wrap', 'nowrap'],
          justifyContent: 'space-between',
        }}
      >
        <Button size="md" type="button" onClick={() => setIsOpen(true)}>
          Create skill
        </Button>
        <SkillsNameSearchBar
          search={search || ''}
          onSearchChange={value => updateQuery('search', value)}
        />
        {isOpen && <CreateSkillDialog closeDialog={() => setIsOpen(false)} />}
      </Flex>
    </HeaderBar>
  );
};
