import * as React from 'react';
import { Button, EditIcon } from '@shortlyster/ui-kit';
import SimpleDate from 'src/components/Date';
import Table from 'src/components/Table';
import styled from 'styled-components';
import { Employer } from './types';

import Link from '../../components/Link';

interface Props {
  employers: Employer[];
}

const StyledButton = styled(Button)`
  border-radius: 6px;
  padding-top: 3px;
  height: 32px;
  margin: 5px 0 5px 0;
  & > div > svg {
    margin-bottom: 2px;
  }
`;

const EmployersList = ({ employers }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Date created</th>
        <th>Modify</th>
      </tr>
    </thead>
    <tbody>
      {employers.map((employer, index) => (
        <tr key={`employer-${index}`}>
          <td>
            {employer.firstname} {employer.lastname}
          </td>
          <td>{employer.email}</td>
          <td>
            <SimpleDate date={employer.createdAt} />
          </td>
          <td>
            <Link to={`/employers/${employer.id}`}>
              <StyledButton icon={EditIcon}>Edit</StyledButton>
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default EmployersList;
