import styled from 'styled-components';
import { FormField } from '@compono/ui';
import { field, InputProps } from 'a-plus-forms';
import { LabelBlock, InputBlock, EmptyLayout } from '@shortlyster/forms-kit';
import Checkbox from 'src/components/Forms/Checkbox';
import { FieldConfiguration } from './queries';

export const InfoBlock = styled.small`
  opacity: 0.5;
  display: block;
  margin: 0;
  margin-bottom: -0.5em;

  ul {
    margin: 0;
  }
`;

const CheckboxLabelAligned = styled(Checkbox)`
  && ${LabelBlock} {
    display: none;
  }

  ${InputBlock} label {
    align-items: center;
    display: flex;
    line-height: inherit;
    margin-top: 0;
  }

  ${InputBlock} span {
    text-transform: capitalize;
    flex: 1;
    margin-left: calc(${p => p.theme.spacingFormLayout} / 2);
  }
`;

type Props = InputProps & {
  value: FieldConfiguration[];
  onChange: (value: FieldConfiguration[]) => void;
};

const HRIntegrationFieldComponent = ({ value, defaultValue, onChange }: Props) => {
  const fields = value || defaultValue;

  const addOrRemove = (checked: boolean, valueToUpdate: FieldConfiguration) => {
    const updated = fields.map((field: FieldConfiguration): FieldConfiguration => {
      const { defaultSelected: _, ...rest } = field;

      // set all job subfields to the same state
      // they're all required; it only makes sense to have all or none
      if (field.fieldType.startsWith('job.')) {
        return {
          ...rest,
          enabled: checked,
        };
        // otherwise, only update the state of the clicked field
      } else if (valueToUpdate.key === field.key) {
        return {
          ...rest,
          enabled: checked,
        };
      }
      // and don't touch unclicked fields
      return field;
    });

    onChange(updated);
  };

  return (
    <FormField
      fieldId="fields"
      label="Select the HRIS fields that this organisation can post to"
      size="sm"
    >
      {fields.map((fieldValue: FieldConfiguration) => (
        <div>
          <CheckboxLabelAligned
            disabled={fieldValue.required}
            key={fieldValue.key}
            value={fieldValue.enabled}
            label={fieldValue.displayName}
            onChange={(checked: boolean) => addOrRemove(checked, fieldValue)}
          />
        </div>
      ))}
    </FormField>
  );
};

/** { array: true } would be ideal, *but* there may be a bug in how that works.
 * the default behaviour of that setting returns booleans because the internal
 * components (the state holders) are checkboxes (ie: bools).
 * */
export default field<Props>({ layout: EmptyLayout, nested: true })(HRIntegrationFieldComponent);
