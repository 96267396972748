import { TextInputField } from '@compono/ui';
import React, { ChangeEvent, FC, KeyboardEvent, useState } from 'react';

interface SkillNameInputProps {
  value: string;
  onChange: (name: string) => void;
  onPressEnter?: () => void;
  showError?: boolean;
}

const SkillNameInput: FC<SkillNameInputProps> = ({
  onChange,
  onPressEnter,
  value,
  showError = true,
}) => {
  const [name, setName] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    setName(newValue);
    onChange(newValue);
  };

  return (
    <TextInputField
      id="skillName"
      label="Skill name"
      placeholder={'e.g. PHP'}
      defaultValue={name}
      onChange={handleChange}
      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter' && onPressEnter) {
          onPressEnter();
        }
      }}
      enterKeyHint=""
      error={!name.trim() && showError ? 'Skill name is required' : undefined}
    />
  );
};

export default SkillNameInput;
