const getMetaTag = (name: string): string => {
  const foundMeta = document.querySelector(`meta[name="${name}"]`);
  const data = foundMeta ? foundMeta.getAttribute('content') : process.env[name];
  return data || '';
};

/* eslint-disable import/no-mutable-exports */
let API_URL = getMetaTag('API_URL') || 'http://localhost:3004';
// NOTE: Uses SL-gateway's auth-cage in order to sync PUI's one
let SHORTLYSTER_URL = getMetaTag('SHORTLYSTER_URL') || 'http://localhost:3000';
const AUTH0_CUSTOM_DOMAIN = getMetaTag('AUTH0_CUSTOM_DOMAIN') || 'auth.dev.compono.dev';
const devDefaultClient = 'NxwPry2Fzdm5KQjHS5puEIGd0RAWABzr'; // compono-client-main
const AUTH0_SPA_CLIENT_ID = getMetaTag('AUTH0_SPA_CLIENT_ID') || devDefaultClient;
const AUTH0_ENV_NAME = getMetaTag('AUTH0_ENV_NAME');
/* eslint-enable import/no-mutable-exports */

const {
  location: { hostname },
} = document;

// when running in acceptar and need to change localhost -> dockerhost
if (API_URL && API_URL.endsWith('localhost:3004') && !API_URL.includes(hostname)) {
  API_URL = API_URL.replace('localhost:', `${hostname}:`);
  SHORTLYSTER_URL = SHORTLYSTER_URL.replace('localhost:', `${hostname}:`);
}

const HEADLESS_TESTING = navigator.userAgent.includes(' HeadlessChrome/');

export {
  API_URL,
  HEADLESS_TESTING,
  SHORTLYSTER_URL,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_SPA_CLIENT_ID,
  AUTH0_ENV_NAME,
};
