import * as React from 'react';
import history from 'lib/history';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Section, Content, Aside, List, TitleSection, Breadcrumb, H1 } from '@shortlyster/ui-kit';
import SimpleDate from 'src/components/Date';
import { useParams } from 'react-router-dom';
import { LegacyPage } from 'components/LegacyPage';
import { Qualification, EditableQualificationProps } from './types';
import QualificationForm from './QualificationsForm';
import Link from '../../components/Link';

interface FetchQualification {
  qualification: Qualification;
}

const fetchQuery = gql`
  query FetchQualification($id: ID!) {
    qualification(id: $id) {
      id
      name
      status
      typeId
      subCategoryIds
      createdAt
      updatedAt
      author {
        id
        firstname
        lastname
        type
      }
    }
  }
`;

interface UpdateQualification {
  updateQualification: {
    id: string;
  };
}

const updateQuery = gql`
  mutation UpdateQualification(
    $id: ID!
    $name: String!
    $status: String!
    $typeId: String!
    $subCategoryIds: [String]!
  ) {
    updateQualification(
      id: $id
      name: $name
      status: $status
      typeId: $typeId
      subCategoryIds: $subCategoryIds
    ) {
      id
    }
  }
`;

const UpdateQualificationsPage = () => {
  const { id } = useParams();
  const { data, loading } = useQuery<FetchQualification>(fetchQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const [updateQualification, { loading: updating }] =
    useMutation<UpdateQualification>(updateQuery);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (!data) {
    return <p>No qualification found...</p>;
  }
  const { qualification } = data;

  const onSubmit = async (newData: EditableQualificationProps) => {
    const variables = { ...newData, id: qualification.id };

    await updateQualification({ variables });
    history.changeDomainTo('/qualifications');
  };

  const { author, createdAt } = qualification;
  const name = author ? `${author.firstname} ${author.lastname}` : 'UNKNOWN';
  const type = author ? `${author.type}` : 'UNKNOWN';

  return (
    <LegacyPage>
      <TitleSection>
        <Breadcrumb>
          <Link to="/qualifications">Qualifications</Link>
          <Link to="" aria-current={true}>
            Edit Qualification
          </Link>
        </Breadcrumb>
        <H1>Edit Qualification</H1>
      </TitleSection>
      <Section>
        <Content>
          <QualificationForm
            onSubmit={onSubmit}
            qualification={qualification}
            disabled={updating}
          />
        </Content>
        <Aside>
          <List>
            <List.Item title="Submitted By">{name}</List.Item>
            <List.Item title="User type">
              <span style={{ textTransform: 'capitalize' }}>{type}</span>
            </List.Item>
            <List.Item title="Date Submitted">
              <SimpleDate date={createdAt} />
            </List.Item>
          </List>
        </Aside>
      </Section>
    </LegacyPage>
  );
};

export default UpdateQualificationsPage;
