import React from 'react';
import { isEmpty } from 'lodash';
import { Caption, color } from '@shortlyster/ui-kit';
import styled from 'styled-components';
import { FormField } from '@compono/ui';
import AppAccess from './AppAccess';
import { OrgAccessApp } from '../types';

type FormAppAccessInput = {
  appCode?: string;
  role?: string;
};

const StyledCaption = styled(Caption)`
  color: ${color('black60')};
`;

interface AppAccessControlProps {
  availableAppsRoles?: OrgAccessApp[];
  currentOrgAccessApps?: FormAppAccessInput[];
  isEditMode: boolean;
  error?: string;
  isFetchingAppsRoles: boolean;
}

export const AppAccessControl: React.FC<AppAccessControlProps> = ({
  currentOrgAccessApps = [],
  availableAppsRoles,
  isEditMode,
  error,
  isFetchingAppsRoles,
}) => {
  const getCurrentAppAccess = (appRoles?: OrgAccessApp): boolean =>
    isEditMode
      ? currentOrgAccessApps?.some(currentAccess => currentAccess.appCode === appRoles?.appCode) ||
        false
      : appRoles?.appCode === 'shortlyster' || false;

  if (isFetchingAppsRoles || isEmpty(availableAppsRoles)) return null;

  return (
    <FormField isGroup label="Application access: *" fieldId="appAccess" error={error}>
      <StyledCaption>Select the application access and roles this user will have</StyledCaption>
      {availableAppsRoles?.map(appRoles => {
        const currentRole = currentOrgAccessApps?.find(
          currentAccess => currentAccess.appCode === appRoles.appCode
        )?.role;
        const accessIsChecked = getCurrentAppAccess(appRoles);
        return (
          <AppAccess
            appCode={appRoles.appCode}
            isChecked={accessIsChecked}
            roles={appRoles.roles}
            currentRole={currentRole}
            key={`appAccess-${appRoles.appCode}`}
          />
        );
      })}
    </FormField>
  );
};
