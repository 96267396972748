import * as React from 'react';
import styled from 'styled-components';
import { Button, SearchIcon } from '@shortlyster/ui-kit';
import { Form, SelectSingle } from '@shortlyster/forms-kit';
import { IconBox, TextInput } from '@compono/ui';

const StyledForm = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.8rem;

  & > div {
    width: 40%;
    margin-right: 1rem;
  }

  & > div > div > input {
    border: none !important;
  }

  & > div > label {
    display: none;
  }
  & > div > div > button {
    border-color: ${p => p.theme.global.colors['dark-5']};
    border-radius: 6px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  height: 42px;
  width: 44px;
  padding: 3px 0 0 3px;
`;

type Option = {
  label: string;
  value: string;
};

interface Props {
  onSubmit: (props: any) => any;
  onChange?: (props: any) => any;
  search?: string;
  options?: Option[];
  defaultValueInOption?: string;
  placeholder?: string;
}

const SearchBar = ({
  onChange,
  onSubmit,
  search,
  options,
  defaultValueInOption,
  placeholder,
}: Props) => (
  <StyledForm onSubmit={onSubmit}>
    <TextInput
      name="searchInput"
      placeholder={placeholder}
      defaultValue={search}
      type="text"
      iconLeft={
        <IconBox
          position="left"
          icon={SearchIcon}
          themeKey="inputIcons"
          size="lg"
          title="Add something"
        />
      }
      size="md"
      onChange={onChange}
    />
    {options ? (
      <SelectSingle name="statusOption" options={options} defaultValue={defaultValueInOption} />
    ) : (
      <> </>
    )}
    <StyledButton icon={SearchIcon} type="submit" />
  </StyledForm>
);

export default SearchBar;
