import gql from 'graphql-tag';
import { PageCounts } from 'src/types/PageCounts';
import { OrgAccess } from '../../Employers/types';

export interface OrganisationTitleDetails {
  id: string;
  name: string;
  metadata?: {
    source?: string;
  };
}

/** Fragment for organisation title details */
const organisationTitleDetailsFragment = gql`
  fragment OrganisationTitleDetailsFragment on Organisation {
    id
    name
    metadata {
      source
    }
  }
`;

export interface Job {
  id: string;
  title: string;
  package?: string;
  status?: 'draft' | 'open' | 'closed' | 'archived';
  createdAt: string;
}

export interface OrganisationJobsQuery {
  organisations: [
    OrganisationTitleDetails & {
      listings: Job[];
      listingsCount: PageCounts;
    }
  ];
}

export const organisationJobsQuery = gql`
  query OrganisationsJobsQuery($id: ID!, $page: Int) {
    organisations(id: $id) {
      ...OrganisationTitleDetailsFragment
      listings(page: $page) {
        id
        title
        package
        status
        createdAt
      }
      listingsCount {
        total
        pages
      }
    }
  }
  ${organisationTitleDetailsFragment}
`;

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  orgAccesses: [OrgAccess];
}

export interface OrganisationUsersQuery {
  organisations: [
    OrganisationTitleDetails & {
      employers: User[];
      employersCount: PageCounts;
    }
  ];
}

export const organisationUsersQuery = gql`
  query OrganisationsUsersQuery($id: ID!, $page: Int) {
    organisations(id: $id) {
      ...OrganisationTitleDetailsFragment
      employers(page: $page) {
        id
        firstname
        lastname
        email
        orgAccesses(organisationId: $id) {
          organisationId
          deactivated
          apps {
            appCode
            roles {
              value
              displayName
            }
          }
          invitation {
            status
          }
        }
      }
      employersCount {
        total
        pages
      }
    }
  }
  ${organisationTitleDetailsFragment}
`;

export interface IdibuClient {
  email: string;
  clientId: string;
}

export interface OrganisationIntegrationsQuery {
  idibuClient: IdibuClient;
}

export const organisationIntegrationsQuery = gql`
  query OrganisationIntegrationsQuery($id: ID!) {
    idibuClient(id: $id) {
      email
      clientId
    }
  }
`;
