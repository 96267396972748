import * as React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Switch } from 'src/components/Forms';

interface Props {
  id: string;
  disabled?: boolean;
}

interface UpdateEmployerStatus {
  updateEmployerStatus: {
    id: string;
  };
}

const updateQuery = gql`
  mutation UpdateEmployerStatus($id: ID!) {
    updateEmployerStatus(id: $id) {
      id
    }
  }
`;

const DisableToggle: React.FC<Props> = ({ id, disabled }) => {
  const [updateEmployerStatus, { loading }] = useMutation<UpdateEmployerStatus>(updateQuery);

  const onChange = async () => {
    return updateEmployerStatus({ variables: { id } });
  };

  return (
    <Switch
      onChange={onChange}
      name="disabled"
      label="Enable / Disable Employer"
      defaultValue={!disabled}
      disabled={loading}
    />
  );
};

export default DisableToggle;
