import { useQuery } from '@apollo/react-hooks';
import { useState } from 'react';
import styled from 'styled-components';
import { field, InputProps } from 'a-plus-forms';
import { LabelBlock, Layout, ErrorBlock, Checkbox, InputBlock } from '@shortlyster/forms-kit';
import { Button } from '@shortlyster/ui-kit';
import { Flex } from '@compono/ui';
import { productLimitsQuery, OrgProduct, Product } from '../queries';
import ProductSection from './ProductSection';

export const InfoBlock = styled.small`
  opacity: 0.5;
  display: block;
`;

const CheckboxLabelAligned = styled(Checkbox)`
  && ${LabelBlock} {
    display: none;
  }

  ${InputBlock} label {
    align-items: center;
    display: flex;
    line-height: inherit;
    margin-top: 0;
  }

  ${InputBlock} span {
    text-transform: capitalize;
    flex: 1;
    margin-left: calc(${p => p.theme.spacingFormLayout} / 2);
  }
`;

const VerticalInputsLayout = styled(Layout)`
  ${CheckboxLabelAligned} {
    margin-top: 0.5rem;
  }

  ${CheckboxLabelAligned} ${LabelBlock} {
    display: none;
  }
`;

type Props = InputProps & {
  value?: OrgProduct[];
  subLabel?: string;
  products: Product[];
};

const AppAcess = ({ value = [], subLabel, products, onChange }: Props) => {
  const { data, loading, error } = useQuery(productLimitsQuery, {
    fetchPolicy: 'cache-and-network',
  });
  const [newInstanceCounter, setNewInstanceCounter] = useState(0);

  const handleProductSelectionChange = (
    code: string,
    instanceId: string | undefined,
    isSelected: boolean
  ) => {
    // deselect product
    if (!isSelected) {
      const newValue = value.filter(p => {
        const shouldRemove = p.productCode === code && p.productInstanceId === instanceId;
        return !shouldRemove;
      });
      onChange(newValue);
      return;
    }

    // select product
    setNewInstanceCounter(newInstanceCounter + 1);
    onChange([
      ...value,
      { productCode: code, productInstanceId: `new-${newInstanceCounter}`, limits: [] },
    ]);
  };

  const handleLimitChange = (
    productCode: string,
    instanceId: string | undefined,
    limitCode: string,
    limit: number
  ) => {
    const product = value.find(
      p => p.productCode === productCode && p.productInstanceId === instanceId
    );
    let newProduct: OrgProduct;
    if (product) {
      newProduct = {
        ...product,
        limits: product.limits?.some(l => l.limitCode === limitCode)
          ? product.limits?.map(l => (l.limitCode === limitCode ? { ...l, limit } : l))
          : [...(product.limits || []), { limitCode, limit }],
      };
    } else {
      newProduct = { productCode, limits: [{ limitCode, limit }] };
    }

    onChange(
      value.map(v =>
        v.productCode === productCode && v.productInstanceId === instanceId ? newProduct : v
      )
    );
  };

  const handleConfigChange = (
    productCode: string,
    instanceId: string | undefined,
    newConfig?: object
  ) => {
    const newValue = value.map(v => {
      if (v.productCode === productCode && v.productInstanceId === instanceId) {
        return { ...v, productConfig: newConfig };
      }
      return v;
    });

    onChange(newValue);
  };

  const handleAddProduct = (product: Product) => {
    setNewInstanceCounter(newInstanceCounter + 1);
    onChange([
      ...value,
      { productCode: product.code, productInstanceId: `new-${newInstanceCounter}`, limits: [] },
    ]);
  };

  const productsAllowMultiple = products.filter(p => p.allowMultiple);

  const showingProducts: { product: Product; orgProduct?: OrgProduct }[] = products.reduce(
    (carry, p) => {
      if (!p.allowMultiple) {
        return [...carry, { product: p, orgProduct: value.find(v => v.productCode === p.code) }];
      }

      const currentOrgProducts = value.filter(v => v.productCode === p.code);

      return [
        ...carry,
        ...(currentOrgProducts.length
          ? currentOrgProducts.map(op => ({ product: p, orgProduct: op }))
          : [{ product: p }]),
      ];
    },
    []
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <ErrorBlock>{error}</ErrorBlock>;

  return (
    <>
      {subLabel && <InfoBlock>{subLabel}</InfoBlock>}
      <Flex sx={{ mt: 5 }}>
        {productsAllowMultiple
          .filter(p => !!showingProducts.find(sp => p.code === sp.orgProduct?.productCode))
          .map(p => (
            <Button key={p.code} onClick={() => handleAddProduct(p)}>
              Add {p.description}
            </Button>
          ))}
      </Flex>
      <Flex sx={{ marginTop: 5, gap: 5, flexWrap: ['wrap'] }}>
        {showingProducts.map(({ product, orgProduct }) => (
          <ProductSection
            key={`${product.code}-${orgProduct?.productInstanceId || ''}`}
            product={product}
            productLimits={data.productLimits}
            orgProduct={orgProduct}
            onProductSelectionChange={handleProductSelectionChange}
            onLimitChange={handleLimitChange}
            onConfigChange={handleConfigChange}
          />
        ))}
      </Flex>
    </>
  );
};

/** { array: true } would be ideal, *but* there may be a bug in how that works.
 * the default behaviour of that setting returns booleans because the internal
 * components (the state holders) are checkboxes (ie: bools).
 * */
export default field<Props>({ layout: VerticalInputsLayout })(AppAcess);
