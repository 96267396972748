import gql from 'graphql-tag';
import { PageCounts } from 'src/types/PageCounts';

export interface Owner {
  firstname: string;
  lastname: string;
  id: string;
}

export interface Resource {
  code: string;
  description: string;
}

export interface Product extends Resource {
  limits: string[];
  requireProvisioning: boolean;
  allowMultiple: boolean;
}

export interface OrgLimit {
  limitCode: string;
  limit: number;
}

export enum OrgProductStatus {
  ACTIVE = 'ACTIVE',
  PROVISIONING_REQUIRED = 'PROVISIONING_REQUIRED',
  PROVISIONING = 'PROVISIONING',
  PROVISIONING_FAILED = 'PROVISIONING_FAILED',
}

export interface OrgProduct {
  productCode: string;
  productStatus: string;
  productInstanceId?: string;
  productExternalId?: string;
  productProvisioningError?: string;
  productConfig?: object;
  limits?: OrgLimit[];
}

export interface Organisation {
  id: string;
  name: string;
  description: string;
  sizeId: string;
  industryId: string;
  location: object;
  products: OrgProduct[];
  metadata?: {
    source?: string;
  };
}

const organisationFragment = gql`
  fragment OrganisationFragment on Organisation {
    id
    name
    description
    sizeId
    industryId
    location {
      country
      state
      city
    }
    metadata {
      source
    }
  }
`;

const organisationProductsFragment = gql`
  fragment OrganisationProductsFragment on Organisation {
    id
    name
    products {
      productCode
      productStatus
      productInstanceId
      productExternalId
      productProvisioningError
      productConfig
      limits {
        limitCode
        limit
      }
    }
    metadata {
      source
    }
  }
`;

const organisationExtraData = gql`
  fragment OrganisationExtraData on Organisation {
    size {
      id
      name
    }
    industry {
      id
      name
    }
  }
`;

export interface OrganisationsQuery {
  organisations: Organisation[];
  organisationsCount: PageCounts;
}

export const organisationsQuery = gql`
  query OrganisationsQuery($search: String, $page: Int!) {
    organisations(search: $search, page: $page) {
      ...OrganisationFragment
    }
    organisationsCount(search: $search) {
      pages
      total
    }
  }
  ${organisationFragment}
`;

export const getOrganisationQuery = gql`
  query GetOrganisation($id: ID!) {
    organisations(id: $id) {
      id
      name
    }
  }
`;

export const productsQuery = gql`
  query ProductsQuery {
    products {
      code
      description
      limits
      requireProvisioning
      allowMultiple
    }
  }
`;

export const productLimitsQuery = gql`
  query ProductLimitsQuery {
    productLimits {
      code
      description
    }
  }
`;

export const fetchAvailableAppsRoles = gql`
  query AvailableAppsRoles {
    availableAppsRoles {
      appCode
      roles {
        displayName
        value
      }
    }
  }
`;

export const fetchAllAppRoles = gql`
  query OrganisationsQuery($id: ID!) {
    allAppRoles(id: $id) {
      appCode
      roles {
        value
        displayName
      }
    }
  }
`;

export interface OrganisationQuery {
  organisations: Organisation[];
}

export const organisationQuery = gql`
  query OrganisationsQuery($id: ID!) {
    organisations(id: $id) {
      ...OrganisationFragment
    }
  }
  ${organisationFragment}
`;

export interface OrganisationProductsQuery {
  organisations: Pick<Organisation, 'products'>[];
}

export const organisationProductsQuery = gql`
  query OrganisationProductsQuery($id: ID!) {
    organisations(id: $id) {
      ...OrganisationProductsFragment
    }
  }
  ${organisationProductsFragment}
`;

export interface OrganisationsSelectQuery {
  organisations?: Array<Pick<Organisation, 'id' | 'name'>>;
}

export const organisationsSelectQuery = gql`
  query Organisations {
    organisations(page: 0) {
      id
      name
    }
  }
`;

export interface OrganisationSize {
  value: string;
  label: string;
}

export interface OrganisationSizesQueryResult {
  organisationSizes: OrganisationSize[];
}

export const OrganisationsSizesQuery = gql`
  query OrganisationSizesQuery {
    organisationSizes {
      value
      label
    }
  }
`;

export interface OrganisationIndustry {
  value: string;
  label: string;
}

export interface OrganisationIndustriesQueryResult {
  organisationIndustries: OrganisationIndustry[];
}

export const OrganisationsIndustriesQuery = gql`
  query OrganisationIndustriesQuery {
    organisationIndustries {
      value
      label
    }
  }
`;

export interface CreateOrganisationMutation {
  createOrganisation: Organisation;
}

export const createOrganisationMutation = gql`
  mutation CreateOrganisation($organisation: OrganisationInput!) {
    createOrganisation(organisation: $organisation) {
      ...OrganisationFragment
      ...OrganisationExtraData
    }
  }
  ${organisationFragment}
  ${organisationExtraData}
`;

export interface UpdateOrganisationMutation {
  updateOrganisation: Organisation;
}

export const updateOrganisationMutation = gql`
  mutation UpdateOrganisation($id: ID!, $organisation: OrganisationInput!) {
    updateOrganisation(id: $id, organisation: $organisation) {
      ...OrganisationFragment
      ...OrganisationExtraData
    }
  }
  ${organisationFragment}
  ${organisationExtraData}
`;

export interface UpdateOrganisationProductsMutation {
  updateOrganisationProducts: Organisation;
}

export const updateOrganisationProductsMutation = gql`
  mutation updateOrganisationProducts($id: ID!, $products: [OrgProductInput!]!) {
    updateOrganisationProducts(id: $id, products: $products) {
      ...OrganisationProductsFragment
    }
  }
  ${organisationProductsFragment}
`;
