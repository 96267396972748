import { FC } from 'react';
import { Button, Flex, Text } from '@compono/ui';
import { CloseOutlineIcon } from '@compono/ui-icons';
import * as React from 'react';

interface SkillsResultBarProps {
  itemRange: string;
  showClearSearch: boolean;
  onClearSearch: () => void;
}

export const SkillsResultBar: FC<SkillsResultBarProps> = ({
  itemRange,
  showClearSearch,
  onClearSearch,
}) => (
  <Flex
    sx={{
      paddingY: 3,
      paddingX: 5,
      gap: 5,
      marginTop: 6,
      backgroundColor: 'black.3',
      borderColor: 'black.10',
      borderWidth: '1px',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      alignItems: 'center',
    }}
  >
    <Text font={'sm'}>Showing results {itemRange}</Text>
    {showClearSearch && (
      <>
        <Text font={'sm'}>|</Text>
        <Button
          look={'naked'}
          size={'sm'}
          iconLeft={CloseOutlineIcon}
          bold={false}
          sx={{ height: 'auto', width: 'auto', lineHeight: '1', padding: 0 }}
          type={'button'}
          onClick={onClearSearch}
        >
          Clear search
        </Button>
      </>
    )}
  </Flex>
);
