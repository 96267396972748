import * as React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import {
  Breadcrumb,
  H1,
  Pagination,
  Section,
  Subtitle2,
  Table,
  TitleSection,
} from '@shortlyster/ui-kit';
import { useParams } from 'react-router-dom';
import useQueryParams from 'src/lib/useQueryParams';
import { organisationUsersQuery, OrganisationUsersQuery, User } from './queries';
import Link from '../../../components/Link';
import getCustomerSubtitle from '../../../lib/getCustomerSubtitle';
import OrganisationNavBar from './OrganisationNavBar';
import EmptyTableMessageContainer from './EmptyTableMessageContainer';
import OrganisationUserRow from './OrganisationUserRow';
import { Product, productsQuery } from '../queries';

const Container = styled.div({
  '.capital': {
    textTransform: 'capitalize',
  },
});

const UsersTable = ({
  users,
  products,
  onUpdateAccess,
}: {
  users: User[];
  products: Product[];
  onUpdateAccess: () => Promise<void>;
}) => {
  const headings = [
    'First name',
    'Last name',
    'Email',
    'Access and Role',
    'Status',
    'Invite Status',
    'Actions',
  ];

  return (
    <Table headings={headings}>
      {users.map(user => (
        <OrganisationUserRow
          key={user.id}
          user={user}
          products={products}
          headings={headings}
          onUpdateAccess={onUpdateAccess}
        />
      ))}
    </Table>
  );
};

const OrganisationUsersPage: React.FC = () => {
  const { id } = useParams();
  const queryParams = useQueryParams();
  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const { data, loading, refetch } = useQuery<OrganisationUsersQuery>(organisationUsersQuery, {
    variables: { id, page },
    fetchPolicy: 'cache-and-network',
  });
  const { data: productsResponse, loading: productsLoading } = useQuery(productsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading || productsLoading) return <p>Loading...</p>;
  if (!data) return <p>Organisation not found...</p>;

  const organisation = data.organisations[0];
  const isNews = organisation?.metadata?.source === 'newscorp';

  const onUpdateAccess = async (): Promise<void> => {
    refetch();
  };

  return (
    <Container>
      <TitleSection>
        <Breadcrumb>
          <Link to="/organisations">Organisations</Link>
          <Link to="" aria-current={true}>
            {organisation.name}
          </Link>
        </Breadcrumb>
        <H1>{organisation.name}</H1>
        <Subtitle2>{getCustomerSubtitle(loading, isNews)}</Subtitle2>
      </TitleSection>
      <OrganisationNavBar organisationId={id as string} active="users" />
      <Section>
        <UsersTable
          onUpdateAccess={onUpdateAccess}
          users={organisation.employers}
          products={productsResponse?.products}
        />
        {organisation.employers.length === 0 && (
          <EmptyTableMessageContainer>
            There are no users associated with this organisation
          </EmptyTableMessageContainer>
        )}
        <Pagination link={Link} page={page} pages={organisation.employersCount.pages || 1} />
      </Section>
    </Container>
  );
};

export default OrganisationUsersPage;
