import React, { FC } from 'react';
import { CoachTip, Flex, Text } from '@compono/ui';
import { createErrorToast, createSuccessToast } from 'components/Toast';
import { Skill } from './types';
import { SkillDetails, SkillDialog } from './Components';
import { SkillService } from './SkillService';

interface RejectSkillDialogProps {
  closeDialog: () => void;
  skill: Skill;
  defaultChecked?: boolean;
}

const RejectSkillDialog: FC<RejectSkillDialogProps> = ({ closeDialog, skill }) => {
  const [rejectSkill, { loading }] = SkillService.useRejectSkill({
    refetchQueries: ['Skills'],
  });

  const onSubmit = async () => {
    try {
      await rejectSkill({
        variables: {
          id: skill.id,
          name: skill.name,
        },
      });

      closeDialog();

      createSuccessToast({
        title: 'Skill status updated',
        message: `Skill "${skill.name}" has been successfully rejected.`,
      });
    } catch {
      createErrorToast({
        title: 'Error',
        message: `Something went wrong updating the skill, please try again later.`,
      });
    }

    closeDialog();
  };

  return (
    <SkillDialog
      title="Reject Skill"
      submitButton="Yes, reject"
      disabled={loading}
      onClose={closeDialog}
      onSubmit={onSubmit}
    >
      <CoachTip
        look="warning"
        title="Are you sure you want to reject this skill?"
        sx={{ marginTop: '10px' }}
      >
        <Text size="md">
          This skill will be permanently removed and will not be available for use by employers or
          candidates.
        </Text>
      </CoachTip>
      <Flex sx={{ flexDirection: 'column', marginTop: 5 }}>
        <Text font="sm" noCrop sx={{ color: 'black.60' }}>
          Skill name
        </Text>
        <Text font="md" noCrop>
          {skill.name}
        </Text>
      </Flex>
      <SkillDetails skill={skill} includeStatus />
    </SkillDialog>
  );
};

export default RejectSkillDialog;
