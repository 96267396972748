import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { Section, Button, EditIcon, Pagination, TitleSection, H1 } from '@shortlyster/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryParams from 'src/lib/useQueryParams';
import SearchBar from 'src/components/SearchBar';
import { organisationsQuery, OrganisationsQuery } from './queries';
import OrganisationsList from './OrganisationList';
import Link from '../../components/Link';

const SearchBarHint = styled.p`
  font-size: 0.8rem;
`;

const AsideRight = styled.div`
  width: 100%;
  text-align: end;
`;

const FoundResult = styled.p`
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  border-radius: 6px;
  padding-top: 3px;
  height: 32px;
  margin: 10px 0 10px 0;
  & > div > svg {
    margin-bottom: 1px;
  }
`;

const OrganisationsPage = () => {
  const [searchInput, setSearchInput] = useState<String>('');
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const search = queryParams.get('search') ?? '';
  const page = parseInt(queryParams.get('page') ?? '1', 10);

  const { data, loading } = useQuery<OrganisationsQuery>(organisationsQuery, {
    variables: search ? { page, search } : { page },
    fetchPolicy: 'cache-and-network',
  });

  const organisations = data?.organisations ?? [];
  const { total, pages } = data?.organisationsCount || {};
  const sortedOrgs = [...organisations].sort((a, b) =>
    a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1
  );

  const onSubmit = () => {
    const url = searchInput ? `/organisations?search=${searchInput}` : `/organisations`;
    history.push(url);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const renderList = () => {
    if (loading) return <p>Loading...</p>;
    return total ? (
      <>
        <OrganisationsList organisations={sortedOrgs} />
        <Pagination link={Link} page={page} pages={pages} location={location.search} />
      </>
    ) : null;
  };

  return (
    <>
      <TitleSection>
        <H1>Organisations</H1>
      </TitleSection>
      <Section>
        <AsideRight>
          <Link to="/organisations/new">
            <StyledButton icon={EditIcon}>Create Organisation</StyledButton>
          </Link>
        </AsideRight>
        <SearchBarHint>Search for an organisation</SearchBarHint>
        <SearchBar
          onSubmit={onSubmit}
          onChange={onChange}
          search={search}
          placeholder={'search organisations'}
        />
        {!!total && !loading ? <FoundResult>{`${total} results found`}</FoundResult> : null}
        {renderList()}
      </Section>
    </>
  );
};

export default OrganisationsPage;
