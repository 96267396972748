import * as React from 'react';
import history from 'lib/history';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Section, TitleSection, Breadcrumb, H1 } from '@shortlyster/ui-kit';
import { LegacyPage } from 'components/LegacyPage';
import OrganisationForm, { NewOrganisation } from './OrganisationForm';
import {
  createOrganisationMutation,
  CreateOrganisationMutation,
  OrganisationsIndustriesQuery,
  OrganisationIndustriesQueryResult,
  OrganisationSizesQueryResult,
  OrganisationsSizesQuery,
} from './queries';
import Link from '../../components/Link';

const CreateOrganisationPage = () => {
  const [createOrganisation, { loading: savingOrg }] = useMutation<CreateOrganisationMutation>(
    createOrganisationMutation
  );
  const { loading: loadingOrgSizes, data: orgSizesQueryResponse } =
    useQuery<OrganisationSizesQueryResult>(OrganisationsSizesQuery, {
      fetchPolicy: 'cache-and-network',
    });
  const { loading: loadingOrgIndustries, data: orgIndustriesQueryResponse } =
    useQuery<OrganisationIndustriesQueryResult>(OrganisationsIndustriesQuery, {
      fetchPolicy: 'cache-and-network',
    });
  const onSubmit = async (data: NewOrganisation) => {
    const result = await createOrganisation({ variables: { organisation: data } });
    if (!result.data) {
      throw new Error('Organisation create failed to return data.');
    }
    const {
      data: {
        createOrganisation: { id },
      },
    } = result;
    history.changeDomainTo(`/organisations/${id}`);
  };

  return (
    <LegacyPage>
      <TitleSection>
        <Breadcrumb>
          <Link to="/organisations">Organisations</Link>
          <Link to="" aria-current={true}>
            Create Organisation
          </Link>
        </Breadcrumb>
        <H1>Create Organisation</H1>
      </TitleSection>
      <Section>
        {loadingOrgSizes || (loadingOrgIndustries && <p>Loading...</p>)}
        {orgSizesQueryResponse && orgIndustriesQueryResponse && (
          <OrganisationForm
            onSubmit={onSubmit}
            disabled={savingOrg}
            sizes={orgSizesQueryResponse.organisationSizes}
            industries={orgIndustriesQueryResponse.organisationIndustries}
          />
        )}
      </Section>
    </LegacyPage>
  );
};

export default CreateOrganisationPage;
