import React, { useEffect } from 'react';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { AUTH0_CUSTOM_DOMAIN, AUTH0_SPA_CLIENT_ID, AUTH0_ENV_NAME } from '../env';

export const AuthBouncer: React.FC = ({ children }) => {
  const history = useHistory();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: history?.location,
        },
      });
    }
  }, [isLoading, isAuthenticated]);

  return isLoading || !isAuthenticated ? <></> : <>{children}</>;
};

/**
 * Need to wrap the Auth0Wrapper with BrowserRouter
 * As such to have access to the session history of the application
 */
export const Auth0Wrapper: React.FC = ({ children }) => {
  const history = useHistory();

  /**
   * Take users back to the route they intended to access before authentication
   */
  const onRedirectCallback = (appState: AppState) =>
    history.push(appState?.returnTo || window.location.pathname);

  return (
    <Auth0Provider
      domain={AUTH0_CUSTOM_DOMAIN}
      clientId={AUTH0_SPA_CLIENT_ID}
      connection={`compono-connection-${AUTH0_ENV_NAME}`}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="compono-api"
      scope="read:all"
    >
      <AuthBouncer>{children}</AuthBouncer>
    </Auth0Provider>
  );
};
