import * as React from 'react';
import { Form as AForm } from 'a-plus-forms';
import { Button, CancelIcon } from '@shortlyster/ui-kit';
import { Textarea, TextInput, LocationSelect, SelectSingle } from '@shortlyster/forms-kit';
import { FormFooter } from 'src/components/Forms';
import history from 'lib/history';
import { Organisation, OrganisationIndustry, OrganisationSize } from './queries';
import { omitNull } from '../../utils';

export type NewOrganisation = Omit<Organisation, 'id'>;

export interface FormProps {
  organisation?: NewOrganisation;
  sizes: OrganisationSize[];
  industries: OrganisationIndustry[];
  onSubmit: (Organisation: NewOrganisation) => void;
  disabled?: boolean;
}

export default ({ organisation, onSubmit, sizes, industries, disabled }: FormProps) => {
  const validate = (input: Partial<Organisation>) => {
    let errors = {};
    if (!input.name) {
      errors = { ...errors, name: 'is required' };
    }
    if (!input.location) {
      errors = { ...errors, location: 'is required' };
    }
    if (!input.sizeId) {
      errors = { ...errors, sizeId: 'is required' };
    }
    if (!input.industryId) {
      errors = { ...errors, industryId: 'is required' };
    }

    return Object.entries(errors).length ? errors : undefined;
  };

  const handleSubmit = (data: unknown) => onSubmit(omitNull(data, true));

  return (
    <AForm onSubmit={handleSubmit} defaultValue={omitNull(organisation ?? {})} schema={validate}>
      <TextInput
        required
        name="name"
        label="Organisation name"
        placeholder="eg. Golding (Functional area 1)"
      />
      <LocationSelect
        required
        name="location"
        label="Organisation location"
        placeholder="Search for location"
      />
      <Textarea
        name="description"
        label="Organisation description"
        placeholder="Golding is a ..."
      />
      <SelectSingle required name="sizeId" options={sizes} label="Size" />
      <SelectSingle required name="industryId" options={industries} label="Industry" />
      <FormFooter>
        <Button disabled={disabled} type="submit">
          Save organisation
        </Button>
        <Button type="reset" icon={CancelIcon} buttonStyle="secondary" onClick={history.goBack}>
          Cancel
        </Button>
      </FormFooter>
    </AForm>
  );
};
