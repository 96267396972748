import * as React from 'react';
import { CancelIcon } from '@shortlyster/ui-kit';
import {
  Form,
  TextInput,
  LocationSelect,
  PhoneInput,
  EmailInput,
  FormFooter,
} from 'src/components/Forms';
import history from 'lib/history';
import { useState } from 'react';
import { Button } from '@compono/ui';
import styled from 'styled-components';
import AccessesPicker from './AccessesPicker';
import { FormProps, OrgAccess } from './types';
import { OrgAccessModal } from './access/OrgAccessModal';

const schema = require('./schema.json');

const SubmitButton = styled(Button)`
  background-color: #ff6600 !important;
  border-color: unset !important;
`;

export default ({ employer, onSubmit, disabled }: FormProps) => {
  const [currentOrgAccess, setCurrentOrgAccess] = useState<OrgAccess | undefined>({});
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (e: MouseEvent) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const openSpecifiedModal = (orgAccess: OrgAccess) => {
    setCurrentOrgAccess(orgAccess);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCurrentOrgAccess(undefined);
    setModalOpen(false);
  };

  return (
    <>
      <Form data-test-id="employerForm" onSubmit={onSubmit} defaultValue={employer} schema={schema}>
        <TextInput name="firstname" label="First name" required placeholder="eg. George" />
        <TextInput name="lastname" label="Last name" required placeholder="eg. Michael" />
        <EmailInput
          name="email"
          label="Email"
          required
          placeholder="eg. george.michael@gmail.com"
        />
        <AccessesPicker
          userId={employer?.id}
          name="orgAccesses"
          label="Organisation/s assigned"
          required
          openModal={openModal}
          openSpecifiedModal={openSpecifiedModal}
        />
        <LocationSelect name="location" label="Location" placeholder="Search for location" />
        <TextInput name="employeeNumber" label="Employee number" placeholder="eg. 4321" />
        <PhoneInput name="phoneWork" label="Work phone number" placeholder="eg (02) 8432 3456" />
        <PhoneInput
          name="phoneMobile"
          label="Mobile phone number"
          placeholder="eg (02) 8432 3456"
        />
        <FormFooter>
          <SubmitButton type="submit" disabled={disabled}>
            Save employer
          </SubmitButton>
          <Button
            look="secondary"
            tone="white"
            iconLeft={CancelIcon}
            type="reset"
            onClick={history.goBack}
          >
            Cancel
          </Button>
        </FormFooter>
      </Form>
      <OrgAccessModal
        user={employer}
        currentOrgAccess={currentOrgAccess}
        open={modalOpen}
        closeModal={closeModal}
      />
    </>
  );
};
