import gql from 'graphql-tag';

const createSkill = gql`
  mutation CreateSkill($name: String!, $status: String!) {
    createSkill(name: $name, status: $status) {
      name
      status
    }
  }
`;

const updateSkill = gql`
  mutation UpdateSkill($id: ID!, $name: String!, $status: String!) {
    updateSkill(id: $id, name: $name, status: $status) {
      id
      name
      status
    }
  }
`;

const approveSkill = gql`
  mutation UpdateSkill($id: ID!, $name: String!) {
    updateSkill(id: $id, name: $name, status: "approved") {
      id
      name
      status
    }
  }
`;

const rejectSkill = gql`
  mutation UpdateSkill($id: ID!, $name: String!) {
    updateSkill(id: $id, name: $name, status: "rejected") {
      id
      name
      status
    }
  }
`;

// Note: In the next commit we are going to replace the approveSkill and rejectSkill mutations
// const approveSkill = gql`
//   mutation ApproveSkill($id: ID!) {
//     approveSkill(id: $id) {
//       id
//       name
//       status
//     }
//   }
// `
//
// const rejectSkill = gql`
//   mutation RejectSkill($id: ID!) {
//     rejectSkill(id: $id) {
//       id
//       name
//       status
//     }
//   }
// `

export const mutations = {
  createSkill,
  updateSkill,
  approveSkill,
  rejectSkill,
};
