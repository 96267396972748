import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  Card,
  CheckboxLabelled,
  Loader,
  Text,
  CoachTip,
  Link,
  Label,
  LinkButton,
} from '@compono/ui';
import { NumberInput, TextInput } from '@shortlyster/forms-kit';
import { Resource, Product, OrgProduct, OrgProductStatus } from '../queries';

export const Divider = styled.div<{ marginTop?: string; marginBottom?: string }>`
  border-top: 1px solid ${p => p.theme.global.colors.black10};
  margin-top: -0.5rem;
  margin-boottom: 2rem;
`;
interface LimitSectionProps {
  key: string;
  productLimits: Resource[];
  limit: string;
  orgProduct?: OrgProduct;
  onLimitChange: (code: string, limit: number | undefined) => void;
  provisioning?: boolean;
}

const LimitSection = ({
  productLimits,
  limit,
  orgProduct,
  onLimitChange,
  provisioning,
}: LimitSectionProps) => {
  const existingLimit = orgProduct?.limits?.length
    ? orgProduct?.limits.find(({ limitCode }) => limitCode === limit)
    : undefined;
  const [unlimited, setUnlimited] = useState(existingLimit?.limit === -1);
  const [newLimit, setNewLimit] = useState(existingLimit?.limit);
  const getProductLimitDesc = (code: string) =>
    productLimits.find(pl => pl.code === code)?.description;

  const handleUnlimitedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setUnlimited(isChecked);
    if (!isChecked) {
      setNewLimit(undefined);
    }
    onLimitChange(limit, isChecked ? -1 : undefined);
  };

  const handleNumberChange = (value: number) => {
    setNewLimit(value);
    onLimitChange(limit, value);
  };

  return (
    <Box as="section" sx={{ marginBottom: '7px' }}>
      <Text sx={{ textTransform: 'capitalize', marginBottom: '7px' }}>
        {getProductLimitDesc(limit)}
      </Text>
      <CheckboxLabelled
        label="Unlimited"
        sx={{ marginBottom: '0px', marginLeft: '-6px' }}
        checked={unlimited}
        onChange={handleUnlimitedChange}
        disabled={provisioning}
      />
      {/* TODO: Use compono-ui's NumberInput once it's fixed */}
      {!unlimited && (
        <NumberInput
          min="0"
          onChange={handleNumberChange}
          layout={null}
          value={newLimit}
          disabled={provisioning}
        />
      )}
    </Box>
  );
};

interface ProductSectionProps {
  key: string;
  productLimits: Resource[];
  product: Product;
  orgProduct?: OrgProduct;
  onProductSelectionChange: (
    productCode: string,
    instanceId: string | undefined,
    isSelected: boolean
  ) => void;
  onLimitChange: (
    productCode: string,
    instanceId: string | undefined,
    limitCode: string,
    limit: number
  ) => void;
  onConfigChange: (productCode: string, instanceId: string | undefined, config: object) => void;
}

const ProductSection = ({
  productLimits,
  product,
  orgProduct,
  onConfigChange,
  onProductSelectionChange,
  onLimitChange,
}: ProductSectionProps) => {
  const [isProductSelected, setIsProductSelected] = useState(!!orgProduct);

  const handleProductCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = !!event.target.checked;
    setIsProductSelected(isSelected);
    onProductSelectionChange(product.code, orgProduct?.productInstanceId, isSelected);
  };

  const handleLimitsChange = (code: string, limit: number) => {
    onLimitChange(product.code, orgProduct?.productInstanceId, code, limit);
  };

  const handleConfigChange = (value: object) => {
    onConfigChange(product.code, orgProduct?.productInstanceId, value);
  };

  const active = orgProduct?.productStatus === OrgProductStatus.ACTIVE;
  const provisioningPending =
    !orgProduct?.productInstanceId || !orgProduct?.productInstanceId.startsWith('new');
  const hasProductConfig = Object.keys(orgProduct?.productConfig || {}).length > 0;
  const provisioning = orgProduct?.productStatus
    ? [OrgProductStatus.PROVISIONING_REQUIRED, OrgProductStatus.PROVISIONING].includes(
        orgProduct.productStatus as OrgProductStatus
      )
    : false;
  const provisioningError = orgProduct?.productProvisioningError;

  return (
    <Card
      as="section"
      tone="white"
      sx={{ width: ['100%', '48%', '32%'], alignContent: 'baseline' }}
    >
      <CheckboxLabelled
        label={product.description}
        sx={{ marginBottom: '10px' }}
        checked={isProductSelected}
        onChange={handleProductCheck}
        disabled={provisioning}
      />
      <Divider />
      {isProductSelected && (
        <>
          <Text bold sx={{ marginBottom: '8px' }}>
            Limits
          </Text>
          {!product.limits?.length && <Text>No limit</Text>}
          {product.limits?.map(limit => (
            <LimitSection
              key={`${product.code}-${limit}`}
              productLimits={productLimits}
              limit={limit}
              orgProduct={orgProduct}
              onLimitChange={handleLimitsChange}
              provisioning={provisioning}
            />
          ))}

          {product.requireProvisioning && (
            <Box
              sx={{
                // small font size, so we can see full product instance id
                'input, label': { fontSize: '0.9em !important', lineHeight: '1em' },
                'input:disabled': { backgroundColor: 'black.10' },
                [`[data-invalid='false']`]: {
                  'input:not(:disabled)': { backgroundColor: 'white' },
                },
              }}
            >
              {(provisioningPending || hasProductConfig) && (
                <Text bold sx={{ marginTop: 7, marginBottom: '8px' }}>
                  Configuration
                </Text>
              )}
              {provisioning && (
                <CoachTip look="information" title="Provisioning">
                  <Loader label="In progress" />
                </CoachTip>
              )}
              {provisioningError && (
                <CoachTip look="warning" title="Provisioning error">
                  <Text>{provisioningError}</Text>
                </CoachTip>
              )}
              {active && <CoachTip title="Provisioned"></CoachTip>}
              {orgProduct?.productInstanceId && !orgProduct.productInstanceId.includes('new') && (
                <TextInput label="Instance ID" value={orgProduct?.productInstanceId} disabled />
              )}
              {product.code === 'develop' && (
                <DevelopProductConfig value={orgProduct?.productConfig} />
              )}
              {product.code === 'clui' && (
                <CluiProductConfig
                  value={orgProduct?.productConfig}
                  onChange={handleConfigChange}
                  provisioning={provisioning}
                  productExternalId={orgProduct?.productExternalId}
                  active={active}
                  provisioningError={provisioningError}
                />
              )}
            </Box>
          )}
        </>
      )}
    </Card>
  );
};

export default ProductSection;

type DevelopProductConfigProps = {
  value?: any;
};

const DevelopProductConfig = ({ value = {} }: DevelopProductConfigProps) => {
  return (
    <>
      {value.enterpriseId && (
        <TextInput label="Enterprise ID" value={value.enterpriseId} disabled />
      )}
      {value.domain && (
        <DisplayField
          label="Domain"
          value={<Link to={`https://${value.domain}`}>{value.domain}</Link>}
        />
      )}
    </>
  );
};

type CluiProductConfigProps = {
  value?: any;
  onChange?: (value: object) => void;
  active?: boolean;
  productExternalId?: string;
  provisioning?: boolean;
  provisioningError?: string;
};

const CluiProductConfig = ({
  value = {},
  onChange,
  active,
  productExternalId,
  provisioning,
  provisioningError,
}: CluiProductConfigProps) => {
  const [enterpriseId, setEnterpriseId] = useState<string>(
    productExternalId || value.enterpriseId || ''
  );
  const [subdomain, setSubdomain] = useState<string>(value.subdomain || '');
  const [name, setName] = useState<string>(value.name || '');

  const handleEnterpriseIdChange = (newValue: string) => {
    setEnterpriseId(newValue);
    onChange?.({ ...value, enterpriseId: newValue });
  };

  const handleSubdomainChange = (newValue: string) => {
    setSubdomain(newValue);
    onChange?.({ ...value, subdomain: newValue });
  };

  const handleNameChange = (newValue: string) => {
    setName(newValue);
    onChange?.({ ...value, name: newValue });
  };

  // Update local state when provisioning status changes
  useEffect(() => {
    setEnterpriseId(productExternalId || value.enterpriseId);
    setSubdomain(value.subdomain);
    setName(value.name);
  }, [active, provisioning]);

  return (
    <>
      <TextInput
        label="Name"
        onChange={handleNameChange}
        value={name}
        disabled={active || provisioning}
      />
      <TextInput
        label="Subdomain"
        onChange={handleSubdomainChange}
        value={subdomain}
        disabled={active || provisioning}
      />
      <TextInput
        label="Enterprise ID"
        onChange={handleEnterpriseIdChange}
        value={enterpriseId}
        disabled={active || provisioning}
      />
      {value.status && <TextInput label="Status" value={value.status} disabled />}
      {value.customDomain && (
        <TextInput label="Custom domain" value={value.customDomain} disabled />
      )}
      {value.domain && (
        <DisplayField
          label="Domain"
          value={<Link to={`https://${value.domain}`}>{value.domain}</Link>}
        />
      )}
    </>
  );
};

const DisplayField = ({ label, value }: { label: string; value: ReactNode }) => (
  <Box sx={{ overflowWrap: 'break-word' }}>
    <br />
    <Label>{label}</Label>
    <br />
    {value}
  </Box>
);
