import styled from 'styled-components';
import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Section, TitleSection, Breadcrumb, H1, Subtitle2, color } from '@shortlyster/ui-kit';
import { Text, TextInput } from '@compono/ui';
import { useParams } from 'react-router-dom';
import { OrganisationIntegrationsQuery, organisationIntegrationsQuery } from './queries';
import Link from '../../../components/Link';
import getCustomerSubtitle from '../../../lib/getCustomerSubtitle';
import OrganisationNavBar from './OrganisationNavBar';
import { OrganisationQuery, organisationQuery } from '../queries';
import HRIntegrations from '../HRIntegrations';

const Container = styled.div({
  '.capital': {
    textTransform: 'capitalize',
  },
});

const StyledText = styled(Text)<{ marginBottom: number }>`
  margin-bottom: ${p => p.marginBottom}rem;
`;

const StyledTextInput = styled(TextInput)`
  border: 0 !important;
`;

const InputWrapper = styled.div`
  margin-bottom: 1.5rem;

  > div {
    background-color: ${color('black5')};
  }
`;

const OrganisationIntegrationsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: orgQueryData, loading: orgQueryLoading } = useQuery<OrganisationQuery>(
    organisationQuery,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }
  );
  const { data: idibuQueryData, loading: idibuQueryLoading } =
    useQuery<OrganisationIntegrationsQuery>(organisationIntegrationsQuery, {
      variables: { id },
      fetchPolicy: 'cache-and-network',
    });

  if (idibuQueryLoading || orgQueryLoading) return <p>Loading...</p>;
  if (!orgQueryData?.organisations?.length) return <p>Organisation not found...</p>;

  const organisation = orgQueryData.organisations[0];
  const isNews = organisation?.metadata?.source === 'newscorp';
  const idibuClient = idibuQueryData?.idibuClient;

  return (
    <Container>
      <TitleSection>
        <Breadcrumb>
          <Link to="/organisations">Organisations</Link>
          <Link to="" aria-current={true}>
            {organisation.name}
          </Link>
        </Breadcrumb>
        <H1>{organisation.name}</H1>
        <Subtitle2>{getCustomerSubtitle(orgQueryLoading, isNews)}</Subtitle2>
      </TitleSection>
      <OrganisationNavBar organisationId={id as string} active="integrations" />
      <Section>
        <StyledText font="h5" marginBottom={1.5}>
          Job board integration (Idibu)
        </StyledText>
        <StyledText marginBottom={1} font="md">
          Idibu client ID:
        </StyledText>
        <InputWrapper>
          <StyledTextInput defaultValue={idibuClient?.clientId} readOnly />
        </InputWrapper>
        <StyledText marginBottom={1} font="md">
          Registered email address:
        </StyledText>
        <InputWrapper>
          <StyledTextInput defaultValue={idibuClient?.email} readOnly />
        </InputWrapper>
      </Section>
      <Section>
        <HRIntegrations organisationId={id} />
      </Section>
    </Container>
  );
};

export default OrganisationIntegrationsPage;
