import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Error as ValidationError } from 'a-plus-forms';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Section, Content, TitleSection, Breadcrumb, H1 } from '@shortlyster/ui-kit';
import history from 'lib/history';
import { LegacyPage } from 'components/LegacyPage';
import { Employer } from './types';
import EmployerForm from './EmployerForm';
import Link from '../../components/Link';
import useOrgAccess from './access/useOrgAccess';
import { EmployerPageType } from './access/orgAccessContext';

interface CreateEmployer {
  createEmployer: {
    id: string;
  };
}

const query = gql`
  mutation CreateEmployer(
    $firstname: String!
    $lastname: String!
    $email: String!
    $location: LocationInput
    $orgAccesses: [OrgAccessInput]!
    $employeeNumber: String
    $phoneWork: String
    $phoneMobile: String
  ) {
    createEmployer(
      firstname: $firstname
      lastname: $lastname
      email: $email
      location: $location
      orgAccesses: $orgAccesses
      employeeNumber: $employeeNumber
      phoneWork: $phoneWork
      phoneMobile: $phoneMobile
    ) {
      id
    }
  }
`;

const CreateEmployerPage = () => {
  const [{ orgAccesses }, dispatch] = useOrgAccess();
  const [createEmployer, { loading }] = useMutation<CreateEmployer>(query);

  useEffect(() => {
    dispatch({
      type: 'setEmployerPageType',
      payload: EmployerPageType.CREATE,
    });
  }, []);

  const onSubmit = async (data: Employer): Promise<void> => {
    if (isEmpty(orgAccesses)) {
      throw new ValidationError({ orgAccesses: 'this is a required field' });
    }
    const variables = {
      ...data,
      orgAccesses: orgAccesses?.map(orgAccess => ({
        organisationId: orgAccess.organisation?.id,
        apps: orgAccess?.apps?.map(app => ({
          ...app,
          roles: app.roles?.map(role => role.value),
        })),
      })),
    };
    const result = await createEmployer({ variables });

    dispatch({
      type: 'reset',
    });
    const id = result.data?.createEmployer.id;
    history.changeDomainTo(`/employers/${id}`);
  };

  return (
    <LegacyPage>
      <TitleSection>
        <Breadcrumb>
          <Link to="/employers">Employers</Link>
          <Link to="" aria-current={true}>
            Create Employer
          </Link>
        </Breadcrumb>
        <H1>Create Employer</H1>
      </TitleSection>
      <Section>
        <Content>
          <EmployerForm onSubmit={onSubmit} disabled={loading} />
        </Content>
      </Section>
    </LegacyPage>
  );
};

export default CreateEmployerPage;
