/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useUser } from '@shortlyster/auth-layer';
import { useAuth0 } from '@auth0/auth0-react';
import { FetchProfile } from './queries';

export const AuthFetcher: React.FC = ({ children }) => {
  const { logout } = useAuth0();
  const [authUser, setUser] = useUser();
  const [ready, setReady] = useState(false);

  const { data, loading, error } = useQuery(FetchProfile);

  useEffect(() => {
    if (error) {
      let errorMsg = error.message;
      if (/.*(401|403).*/.test(errorMsg)) {
        errorMsg = 'You do not have permission';
        // TODO: Use a proper error page
        window.alert(errorMsg);
        logout({ returnTo: window.location.origin });
      }
    }
  }, [error]);

  useEffect(() => {
    if (loading) return;

    if (data?.profile && !error) {
      setUser(data.profile).then(() => setReady(true));
    } else if (authUser) {
      setUser(null).then(() => setReady(true));
    } else {
      setReady(true);
    }
  }, [loading, data]);

  return ready && !error ? <>{children}</> : null;
};
