import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { TitleSection, H1, Pagination } from '@shortlyster/ui-kit';
import { StatusSelect } from 'src/components/Forms';
import useQueryParams from 'src/lib/useQueryParams';
import { LegacyPage } from 'components/LegacyPage';
import { Flex, Loader } from '@compono/ui';
import QualificationsList from './QualificationsList';
import { Qualification, Status } from './types';
import Link from '../../components/Link';

interface QualificationsQuery {
  qualifications?: Qualification[];
  qualificationsCount: {
    pages: number;
  };
}

const query = gql`
  query QualificationsQuery($page: Int!, $status: String, $orderBy: QualOrderBy, $order: Order) {
    qualifications(page: $page, status: $status, orderBy: $orderBy, order: $order) {
      id
      name
      status
      author {
        id
        firstname
        lastname
        type
      }
      createdAt
      updatedAt
    }
    qualificationsCount(status: $status) {
      pages
    }
  }
`;

const QualificationsPage = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const status = queryParams.get('status') ?? 'draft';
  const { data, loading } = useQuery<QualificationsQuery>(query, {
    variables: {
      page,
      status,
      orderBy: status === 'draft' ? 'createdAt' : 'name',
      order: status === 'draft' ? 'desc' : 'asc',
    },
    fetchPolicy: 'cache-and-network',
  });

  const queryResult = !loading && data ? data : undefined;
  const qualifications = queryResult?.qualifications || [];
  const qualificationsCount = queryResult?.qualificationsCount || { pages: 0 };

  const onChange = (newStatus: Status) => {
    history.push(`/qualifications?status=${newStatus}`);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Flex sx={{ flex: 1, alignItems: 'center' }}>
          <Loader alignment={'center'} label={'Loading...'} />
        </Flex>
      );
    }

    if (!queryResult) return null;

    return qualifications.length ? (
      <>
        <QualificationsList qualifications={qualifications} status={status} />
        <Pagination
          link={Link}
          page={page}
          pages={qualificationsCount.pages}
          location={`?status=${status}&`}
        />
      </>
    ) : (
      <p>No qualifications found...</p>
    );
  };

  return (
    <LegacyPage>
      <Flex
        sx={{
          paddingBottom: 9,
          flexDirection: 'column',
          minHeight: 'calc(100vh - 56px)', // 56px is the header height
        }}
      >
        <TitleSection>
          <H1>Qualifications</H1>
        </TitleSection>
        <StatusSelect value={status} onChange={onChange} />
        {renderContent()}
      </Flex>
    </LegacyPage>
  );
};

export default QualificationsPage;
