import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import {
  AdminIcon,
  ComponoIcon,
  ComponoLogo,
  EmployerIcon,
  LogoMenuItem,
  LogoutIcon,
  MenuItem,
  NavBar,
  OrganisationIcon,
  QualificationIcon,
  SkillsIcon,
  Spacer,
  TemplateIcon,
} from '@shortlyster/ui-kit';
import { useUser } from '@shortlyster/auth-layer';
import { SHORTLYSTER_URL } from 'lib/env';
import styled from 'styled-components';
import Link from './Link';

const isRoute = (route, location) => location.pathname.includes(route);

type MenuItemCurrentType = {
  icon: React.ReactChild;
  to: string;
  label: string;
};

const StyledMenuItem = styled(MenuItem)`
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const MenuItemCurrent = ({ icon, to, label }: MenuItemCurrentType) => {
  const location = useLocation();
  return (
    <StyledMenuItem icon={icon} to={to} current={isRoute(to, location)} link={Link}>
      {label}
    </StyledMenuItem>
  );
};

const Navigation = () => {
  const { logout } = useAuth0();
  const [, setUser] = useUser();

  const signOut = () => {
    setUser(null);
    window.sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  return (
    <NavBar>
      <LogoMenuItem link={Link} to="/skills" desktop={ComponoLogo} mobile={ComponoIcon}>
        Shortlyster Admin
      </LogoMenuItem>
      <Spacer />
      <MenuItemCurrent
        icon={TemplateIcon}
        to={`${SHORTLYSTER_URL}/templates`}
        label="Job templates"
      />
      <MenuItemCurrent icon={SkillsIcon} to="/skills" label="Skills" />
      <MenuItemCurrent
        icon={QualificationIcon}
        to="/qualifications"
        label="Qualifications & Certifications"
      />
      <MenuItemCurrent icon={EmployerIcon} to="/employers" label="Employers" />
      <MenuItemCurrent icon={OrganisationIcon} to="/organisations" label="Organisations" />
      <MenuItemCurrent icon={AdminIcon} to="/admins" label="Admins" />
      <StyledMenuItem icon={LogoutIcon} link={Link} onClick={signOut}>
        Log out
      </StyledMenuItem>
    </NavBar>
  );
};

export default Navigation;
