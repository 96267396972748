import * as React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Section, Content, Button, CreateIcon, TitleSection, H1 } from '@shortlyster/ui-kit';
import { ComponoUser as Admin, AdminsQueryResult, UpdateAdminAccessInput } from './types';
import { AdminsQuery, UpdateAdminAccess } from './queries';
import AdminsList from './AdminsList';

const AddButton = styled(Button)`
  margin-bottom: 1.5rem;
`;

const AdminsPage = () => {
  const history = useHistory();

  const { data, loading } = useQuery<AdminsQueryResult>(AdminsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateAdminAccess] = useMutation<any, UpdateAdminAccessInput>(UpdateAdminAccess, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: AdminsQuery }],
  });
  const { adminUsers } = data ?? {};

  const sortedAdmins = adminUsers
    ? [...adminUsers].sort((a, b) =>
        (a.firstname || '').toLowerCase() >= (b.firstname || '').toLowerCase() ? 1 : -1
      )
    : [];

  const addAdmin = () => history.push('/admins/new');

  const removeAdmin = (admin: Admin) => {
    updateAdminAccess({
      variables: {
        userId: admin.id,
        isAdmin: false,
      },
    });
  };

  return (
    <>
      <TitleSection>
        <H1>Admins</H1>
      </TitleSection>
      <Section>
        <Content>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <AddButton icon={CreateIcon} onClick={addAdmin}>
                Add admin
              </AddButton>
              <AdminsList
                admins={sortedAdmins}
                onRemove={(admin: Admin) =>
                  window.confirm(`Are you sure you wish to remove Admin access for this user?`) &&
                  removeAdmin(admin)
                }
              />
            </>
          )}
        </Content>
      </Section>
    </>
  );
};

export default AdminsPage;
