import { Text, CoachTip } from '@compono/ui';
import React, { FC, useState } from 'react';
import { createSuccessToast, createErrorToast } from 'components/Toast';
import { Skill } from './types';
import { SkillNameInput, SkillStatusInput, SkillDetails, SkillDialog } from './Components';
import { SkillService } from './SkillService';

interface EditSkillDialogProps {
  closeDialog: () => void;
  skill: Skill;
}

const EditSkillDialog: FC<EditSkillDialogProps> = ({ closeDialog, skill }) => {
  const [currentSkill, setSkill] = useState(skill);
  const [updateSkill, { loading }] = SkillService.useUpdateSkill({
    refetchQueries: ['Skills'],
  });

  const onSubmit = async () => {
    try {
      await updateSkill({
        variables: {
          id: currentSkill.id,
          name: currentSkill.name,
          status: currentSkill.status,
        },
      });
      closeDialog();

      if (skill.status !== currentSkill.status)
        createSuccessToast({
          title: 'Skill status updated',
          message: `Skill "${currentSkill.name}" has been successfully ${currentSkill.status}.`,
        });
    } catch {
      createErrorToast({
        title: 'Error',
        message: `Something went wrong updating the skill, please try again later.`,
      });
    }
  };

  return (
    <SkillDialog
      title="Edit Skill"
      disabled={!currentSkill.name || loading}
      onClose={closeDialog}
      onSubmit={onSubmit}
    >
      <SkillNameInput
        value={currentSkill.name}
        onChange={name => {
          setSkill({
            ...currentSkill,
            name,
          });
        }}
        onPressEnter={onSubmit}
      />
      <SkillStatusInput
        value={skill.status}
        onChange={status => {
          setSkill({
            ...currentSkill,
            status,
          });
        }}
        // Compono skills can't be rejected until skill groups have been migrated to mongo
        disableReject={skill.source === 'compono'}
      />
      {skill.status === 'approved' && currentSkill.status === 'rejected' ? (
        <CoachTip
          look="warning"
          title="Are you sure you want to reject this skill?"
          sx={{ marginTop: '10px' }}
        >
          <Text size="md">
            This skill will be permanently removed and will not be available for use by employers or
            candidates.
          </Text>
        </CoachTip>
      ) : null}
      <SkillDetails skill={currentSkill} />
    </SkillDialog>
  );
};

export default EditSkillDialog;
