import { isEqual } from 'lodash';
import { createContext } from 'react';
import { AppRoles, OrgAccess } from '../types';

export enum EmployerPageType {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export interface IAvailableAppsRoles {
  organisationId: string;
  appsRoles: AppRoles[];
}
export interface OrgAccessContextState {
  availableAppsRoles: IAvailableAppsRoles[];
  orgAccesses: OrgAccess[];
  employerPageType: EmployerPageType;
  currentOrgAccess: OrgAccess | undefined;
  error: Error | undefined;
}

export const initialState = {
  availableAppsRoles: [],
  orgAccesses: [],
  employerPageType: EmployerPageType.CREATE,
  currentOrgAccess: undefined,
  error: undefined,
};

export const currentOrgIndex = (
  totalSet: OrgAccess[],
  current: OrgAccess,
  type: EmployerPageType
): number => {
  if (type === EmployerPageType.CREATE) {
    return totalSet?.findIndex(
      (orgAccess: OrgAccess) => orgAccess.organisation?.id === current.organisation?.id
    );
  }
  if (type === EmployerPageType.EDIT) {
    return totalSet?.findIndex(
      (orgAccess: OrgAccess) => orgAccess.organisationId === current.organisationId
    );
  }
  return -1;
};

// convert role from string to displayName and value obj
const appRolesConvertor = (orgAccess: OrgAccess, availableAppsRoles: IAvailableAppsRoles[]) => {
  return orgAccess?.apps?.map(app => {
    const currentOrgAppsRole = availableAppsRoles.find(
      appsRole =>
        appsRole.organisationId === (orgAccess?.organisation?.id ?? orgAccess?.organisationId)
    )?.appsRoles;
    const roleOptions = currentOrgAppsRole?.find(appsRole => appsRole.appCode === app.appCode);
    const displayName = roleOptions?.roles.find(r => r.value === (app.roles?.[0] as string))
      ?.displayName;
    return {
      appCode: app.appCode,
      roles: [
        {
          displayName,
          value: app.roles?.[0],
        },
      ],
    };
  });
};

export type Context = [OrgAccessContextState, Function];

const orgAccessContext = createContext<Context>([initialState, () => {}]);

export const reducer = (state: OrgAccessContextState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case 'setOrgAccesses':
      return {
        ...state,
        orgAccesses: action.payload,
      };
    case 'addOrgAccesses': {
      const orgAccess: OrgAccess = action.payload;
      const normalizedApps = appRolesConvertor(orgAccess, state.availableAppsRoles);
      return {
        ...state,
        orgAccesses: [
          ...state.orgAccesses,
          {
            ...orgAccess,
            apps: normalizedApps,
          },
        ],
      };
    }
    case 'removeOrgAccesses': {
      const index = currentOrgIndex(state.orgAccesses, action.payload, state.employerPageType)!;
      const orgAccesses = [...state.orgAccesses];
      orgAccesses.splice(index, 1);
      return {
        ...state,
        orgAccesses,
      };
    }
    case 'editOrgAccess': {
      const index = currentOrgIndex(state.orgAccesses, action.payload, state.employerPageType)!;
      const orgAccess: OrgAccess = action.payload;
      const normalizedApps = appRolesConvertor(orgAccess, state.availableAppsRoles);

      const newOrgAccesses = [...state.orgAccesses];
      newOrgAccesses[index] = {
        ...orgAccess,
        apps: normalizedApps,
      };
      return {
        ...state,
        orgAccesses: newOrgAccesses,
      };
    }
    case 'setAvailableAppsRoles': {
      const existedAppsRoles = state.availableAppsRoles.find(
        current => current.organisationId === action.payload.organisationId
      );
      const notEqual = !isEqual(existedAppsRoles, action.payload);
      return {
        ...state,
        availableAppsRoles:
          !existedAppsRoles || notEqual
            ? [...state.availableAppsRoles, action.payload]
            : state.availableAppsRoles,
      };
    }
    case 'setEmployerPageType':
      return {
        ...state,
        employerPageType: action.payload,
      };
    case 'setError':
      return {
        ...state,
        error: action.payload,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

export default orgAccessContext;
