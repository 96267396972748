import styled from 'styled-components';
import { FormField, Label } from '@compono/ui';
import { field, InputProps } from 'a-plus-forms';
import { LabelBlock, Layout, InputBlock } from '@shortlyster/forms-kit';
import Checkbox from './Checkbox';

export const InfoBlock = styled.small`
  opacity: 0.5;
  display: block;
`;

const CheckboxLabelAligned = styled(Checkbox)`
  && ${LabelBlock} {
    display: none;
  }

  ${InputBlock} label {
    align-items: center;
    display: flex;
    line-height: inherit;
    margin-top: 0;
  }

  ${InputBlock} span {
    text-transform: capitalize;
    flex: 1;
    margin-left: calc(${p => p.theme.spacingFormLayout} / 2);
  }
`;

const VerticalInputsLayout = styled(Layout)`
  ${CheckboxLabelAligned} {
    margin-top: 0.5rem;
  }

  ${CheckboxLabelAligned} ${LabelBlock} {
    display: none;
  }
`;

type CheckboxOption = {
  value: string;
  label: string;
};

type CheckboxValue = {
  value: string;
  selected?: boolean;
};

type Props = InputProps & {
  name: string;
  subLabel?: string;
  label?: string;
  options?: CheckboxOption[];
  value?: CheckboxValue[];
};

const CheckboxListComponent = ({
  label,
  subLabel,
  onChange,
  value = [],
  options = [],
  name,
}: Props) => {
  const addOrRemove = (checked: boolean, current: boolean, valueName: string) => {
    if (onChange && current !== checked) {
      const updated = value.map((v: CheckboxValue): CheckboxValue => {
        const selected = valueName === v.value ? checked : v.selected;
        return {
          value: v.value,
          selected,
        };
      });

      onChange(updated);
    }
  };

  return (
    <FormField fieldId={name} label={label} size="sm">
      {subLabel && <Label id={`${name}Label`}>{subLabel}</Label>}
      {options.map((option: CheckboxOption) => (
        <CheckboxLabelAligned
          key={option.value}
          onChange={(checked: boolean) =>
            addOrRemove(
              checked,
              value.find((item: CheckboxValue) => item.value === option.value)?.selected,
              option.value
            )
          }
          value={value.find((item: CheckboxValue) => item.value === option.value)?.selected}
          label={option.label}
        />
      ))}
    </FormField>
  );
};

/** { array: true } would be ideal, *but* there may be a bug in how that works.
 * the default behaviour of that setting returns booleans because the internal
 * components (the state holders) are checkboxes (ie: bools).
 * */
export default field<Props>({ layout: VerticalInputsLayout })(CheckboxListComponent);
