import { FC, ReactNode, SVGProps } from 'react';
import { Box, Button, Flex, Heading, Text } from '@compono/ui';
import {
  CloseOutlineIcon,
  WindowApplicationSearchOutlineIcon,
  ReloadOutlineIcon,
  ExclamationTriangleOutlineIcon,
} from '@compono/ui-icons';
import * as React from 'react';

interface BaseProps {
  icon: ReactNode;
  heading: string;
  description: string;
  buttonText: string;
  buttonIcon: FC<SVGProps<SVGSVGElement>>;
  onButtonClick: () => void;
}

const EmptyStateBase: FC<BaseProps> = ({
  icon,
  heading,
  description,
  buttonText,
  buttonIcon,
  onButtonClick,
}) => (
  <Flex
    sx={{
      alignItems: 'center',
      flexDirection: 'column',
      maxWidth: '500px',
      padding: 6,
      margin: '0 auto',
      marginTop: 5,
      textAlign: 'center',
    }}
  >
    <Box sx={{ color: 'black.20', svg: { width: '100px', height: '100px' } }}>{icon}</Box>
    <Heading font={'h5'} as={'h2'} noCrop={true}>
      {heading}
    </Heading>
    <Text noCrop={true}>{description}</Text>
    <Button
      look={'naked'}
      iconLeft={buttonIcon}
      sx={{ height: 'auto', lineHeight: '1', padding: 0, marginTop: 5 }}
      type={'button'}
      onClick={onButtonClick}
    >
      {buttonText}
    </Button>
  </Flex>
);

interface SkillsEmptyProps {
  searchTerm?: string;
  onClearFilters: () => void;
  hasChangedFilters: boolean;
}

export const SkillsEmpty: FC<SkillsEmptyProps> = ({
  searchTerm,
  onClearFilters,
  hasChangedFilters,
}) => (
  <EmptyStateBase
    icon={<WindowApplicationSearchOutlineIcon />}
    buttonIcon={CloseOutlineIcon}
    buttonText={`Clear ${hasChangedFilters ? 'filters' : 'search'}`}
    heading={`No results found${searchTerm ? ` for "${searchTerm}"` : ''}`}
    description={'Please make sure all words are spelled correctly and filters are set correctly.'}
    onButtonClick={onClearFilters}
  />
);

interface SkillsErrorProps {
  onRetryClick: () => void;
}

export const SkillsError: FC<SkillsErrorProps> = ({ onRetryClick }) => (
  <EmptyStateBase
    icon={<ExclamationTriangleOutlineIcon />}
    buttonIcon={ReloadOutlineIcon}
    buttonText={'Retry'}
    heading={'Failed to load results'}
    description={'Sorry, something went wrong and we were unable to load the results.'}
    onButtonClick={onRetryClick}
  />
);
