import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { Grid } from '@compono/ui';
import { Checkbox, SelectSingle } from '../../../components/Forms';
import { Role } from '../types';
import { productsQuery } from '../../Organisations/queries';

const Container = styled(Grid)`
  grid-template-columns: 10em 1fr;
`;

const covertOptions = (roles: Role[] = []) =>
  roles.map(roleOption => ({
    value: roleOption?.value,
    label: roleOption.displayName,
  }));

interface AppAccessProps {
  appCode: string;
  roles: Role[];
  currentRole?: string;
  isChecked: boolean;
}

export default ({ appCode, roles, isChecked, currentRole }: AppAccessProps) => {
  const [internalIsChecked, setInternalIsChecked] = useState(isChecked);
  const { data: productsInfo } = useQuery(productsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const onCheckboxChange = (value: boolean) => {
    setInternalIsChecked(value);
  };

  const getAppName = (code: string) =>
    productsInfo?.products?.find(p => p.code === code)?.description || code;

  return (
    <Container>
      <Checkbox
        name={`${appCode}-checkbox`}
        label={getAppName(appCode)}
        value={internalIsChecked}
        onChange={onCheckboxChange}
      />
      <SelectSingle
        name={`${appCode}-roleSelect`}
        placeholder="Select role"
        required={appCode === 'shortlyster'}
        defaultValue={currentRole || ''}
        disabled={!internalIsChecked}
        options={covertOptions(roles)}
      />
    </Container>
  );
};
