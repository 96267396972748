import * as React from 'react';
import { Button, DeleteIcon } from '@shortlyster/ui-kit';
import SimpleDate from 'src/components/Date';
import Table from 'src/components/Table';
import { ComponoUser as Admin } from './types';

interface Props {
  admins: Admin[];
  onRemove: (admin: Admin) => void;
}

const AdminsList = ({ admins, onRemove }: Props) => (
  <Table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Date created</th>
        <th>Remove Access</th>
      </tr>
    </thead>
    <tbody>
      {admins.map((admin, index) => (
        <tr key={`admin-${index}`}>
          <td>
            {admin.firstname} {admin.lastname}
          </td>
          <td>{admin.email}</td>
          <td>
            <SimpleDate date={admin.accessGranted} />
          </td>
          <td>
            <Button onClick={() => onRemove(admin)} icon={DeleteIcon} buttonStyle="secondary" />
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default AdminsList;
