import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import { Error as ValidationError } from 'a-plus-forms';
import { color, breakpoint } from '@shortlyster/ui-kit';
import {
  Dialog,
  DialogHeading,
  DialogPrimaryActions,
  Button,
  DialogCloseButton,
  DialogBody,
} from '@compono/ui';
import { EmptyLayout } from '@shortlyster/forms-kit';
import { isEmpty, omit } from 'lodash';
import { Form } from '../../../components/Forms';
import { Employer, OrgAccess, AppAccessInput, GetOrganisation, AllAppsRolesType } from '../types';
import { Organisation, getOrganisationQuery, fetchAllAppRoles } from '../../Organisations/queries';
import { AppAccessControl } from './AppAccessControl';
import OrganisationSelect from '../../Organisations/OrganisationSelect';
import appAccessesFormValidation from './appAccessesFormValidation';
import useOrgAccess from './useOrgAccess';
import { currentOrgIndex, EmployerPageType } from './orgAccessContext';

/* eslint-disable import/extensions */
const schema = require('./schema.json');
/* eslint-enable */

const StyledDialog = styled(Dialog)`
  @media ${breakpoint('maxTablet')} {
    width: 100% !important;
    margin: 0px !important;
  }
`;

const StyledDialogHeading = styled(DialogHeading)`
  padding: 1.2rem 1.5rem;
`;

const StyledDialogBody = styled(DialogBody)`
  padding: 2rem 2rem 4rem;
  border-bottom: 1px solid ${color('black10')};
`;

const StyledForm = styled(Form)`
  margin-bottom: 0;

  > small {
    font-size: 1rem !important;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 1.5rem;
  }
`;

const EmptyDialogTitle = styled(DialogHeading)`
  padding: 2rem;

  svg {
    right: unset;
  }
`;

const SubmitButton = styled(Button)`
  @media ${breakpoint('maxMobile')} {
    margin-left: 0 !important;
    margin-bottom: 1rem;
  }
`;

const Email = styled.p`
  margin: 0;
`;

const StyledOrganisationSelect = styled(OrganisationSelect)`
  margin-bottom: 1.5rem;
`;

export type formError = {
  [appCode: string]: string;
};

interface OrgAccessModalProps {
  open: boolean;
  closeModal: () => void;
  user?: Employer;
  currentOrgAccess: OrgAccess | undefined;
}

export const OrgAccessModal: React.FC<OrgAccessModalProps> = ({
  open,
  closeModal,
  user,
  currentOrgAccess,
}) => {
  const currentOrgAccessApps =
    currentOrgAccess?.apps?.map(access => ({
      appCode: access.appCode,
      role: access.roles[0].value,
    })) || undefined;

  const [error, setError] = useState<string | undefined>();
  const existedUserOrgId = currentOrgAccess?.organisationId;
  const newUserOrgId = currentOrgAccess?.organisation?.id;
  const isEditMode = !!newUserOrgId || !!existedUserOrgId;

  const [{ orgAccesses, employerPageType }, dispatch] = useOrgAccess();

  const [currentOrg, setCurrentOrg] = useState<Partial<Organisation> | undefined>(
    currentOrgAccess?.organisation
  );
  const [getOrg, { data: orgData }] = useLazyQuery<GetOrganisation>(getOrganisationQuery);
  const [getAvailableAppsRoles, { data: availableAppsRoles, loading: isFetchingAppsRoles }] =
    useLazyQuery<AllAppsRolesType>(fetchAllAppRoles, {
      variables: { id: currentOrg?.id },
      fetchPolicy: 'no-cache',
      onCompleted: res =>
        dispatch({
          type: 'setAvailableAppsRoles',
          payload: {
            organisationId: currentOrg?.id,
            appsRoles: res.allAppRoles,
          },
        }),
    });

  useEffect(() => {
    if (!existedUserOrgId) return;
    getOrg({ variables: { id: existedUserOrgId } });
  }, [existedUserOrgId]);

  useEffect(() => {
    let org;
    if (orgData?.organisations?.length) {
      org = omit(orgData?.organisations?.[0], ['__typename']);
    }
    if (currentOrgAccess?.organisation) {
      org = currentOrgAccess?.organisation;
    }
    setCurrentOrg(org);
  }, [orgData, currentOrgAccess]);

  useEffect(() => {
    if (currentOrg) {
      getAvailableAppsRoles();
    }
  }, [currentOrg]);

  const handleCancel = () => {
    // clean up
    setCurrentOrg(undefined);
    closeModal();
  };

  const onSubmit = async (formData: {
    organisation: Partial<Organisation>;
    appAccessForm: { [name: string]: string | boolean | undefined };
  }) => {
    const { organisation, ...appAccessForm } = formData;

    const payload: OrgAccess = {};
    if (employerPageType === EmployerPageType.CREATE) {
      payload.organisation = organisation;
    }
    if (employerPageType === EmployerPageType.EDIT) {
      payload.organisationId = organisation?.id;
    }

    // block when adding duplicated org
    const isExisted = currentOrgIndex(orgAccesses, payload, employerPageType) !== -1;
    if (!isEditMode && isExisted) {
      throw new ValidationError({ organisation: "Organisation's already existed" });
    }

    try {
      // block if errors exist
      appAccessesFormValidation(appAccessForm);
    } catch (err) {
      if (err.errors === 'You must select at least one application access') {
        setError(err.errors);
        return;
      }
      setError(undefined);
      throw err;
    }
    setError(undefined);

    const appAccesses = Object.entries(appAccessForm).reduce(
      (result: AppAccessInput[], [formName, formValue], _, originalArr) => {
        if (formName.includes('roleSelect')) {
          const appCode = formName.split('-')[0];
          const isChecked = originalArr.find(([key]) => key === `${appCode}-checkbox`)?.[1];
          if (!isChecked) return result;

          const role = formValue;
          result.push({
            appCode,
            roles: [role as string],
          });
        }
        return result;
      },
      []
    );

    payload.apps = appAccesses;

    dispatch({
      type: isEditMode ? 'editOrgAccess' : 'addOrgAccesses',
      payload,
    });
    handleCancel();
  };

  const handleRemoveOrgAccess = () => {
    dispatch({
      type: 'removeOrgAccesses',
      payload: currentOrgAccess,
    });
    handleCancel();
  };

  return (
    <StyledDialog
      aria-labelledby={`org-access-${user?.id}`}
      aria-describedby={'dialog-description'}
      isOpen={open}
      onDismiss={handleCancel}
    >
      <StyledForm schema={schema} onSubmit={onSubmit}>
        <DialogCloseButton onClick={closeModal} />
        {!isEmpty(user) ? (
          <StyledDialogHeading as="h2">
            <span>{`${user?.firstname} ${user?.lastname}`}</span>
            <Email>{user?.email}</Email>
          </StyledDialogHeading>
        ) : (
          <EmptyDialogTitle />
        )}
        <StyledDialogBody>
          <StyledOrganisationSelect
            required
            label="Organisation"
            name="organisation"
            placeholder="Employer org"
            layout={EmptyLayout}
            disabled={isEditMode}
            defaultValue={isEditMode && currentOrg}
            onChange={(value: Partial<Organisation>) => setCurrentOrg(value)}
          />

          <AppAccessControl
            isEditMode={isEditMode}
            availableAppsRoles={availableAppsRoles?.allAppRoles}
            isFetchingAppsRoles={isFetchingAppsRoles}
            currentOrgAccessApps={currentOrgAccessApps}
            error={error}
          />
        </StyledDialogBody>
        <DialogPrimaryActions>
          {isEditMode ? (
            <Button
              look="secondary"
              tone="critical"
              data-test-id="remove"
              size="md"
              onClick={handleRemoveOrgAccess}
            >
              Remove organisation for user
            </Button>
          ) : (
            <Button look="secondary" data-test-id="cancel" size="md" onClick={handleCancel}>
              Cancel
            </Button>
          )}
          <SubmitButton data-test-id="save" size="md" type="submit">
            Add
          </SubmitButton>
        </DialogPrimaryActions>
      </StyledForm>
    </StyledDialog>
  );
};
