import { useMutation } from '@apollo/react-hooks';
import { DeclinedIcon, AcceptedIcon, SubmenuLink } from '@shortlyster/ui-kit';
import { OrgAccess } from './types';
import { deactivateEmployerMutation, DeactivatedInput, DeactivatedOutput } from './queries';

type Props = {
  orgAccess?: OrgAccess;
  userId?: string;
  onDeactivateUser: (orgAccesses: OrgAccess[]) => Promise<void>;
};

const DeactivateUser = ({ userId, orgAccess, onDeactivateUser }: Props) => {
  const organisationId = orgAccess?.organisation?.id || orgAccess?.organisationId;

  if (!organisationId || !userId || !orgAccess) return <></>;

  const [updateOrgAccess, { loading }] = useMutation<DeactivatedOutput, DeactivatedInput>(
    deactivateEmployerMutation
  );

  const deactivateUser = async (deactivated: boolean) => {
    const variables = {
      id: userId,
      organisationId,
      deactivated,
    };

    updateOrgAccess({ variables }).then(result => {
      if (result.data) {
        onDeactivateUser(result.data?.deactivateEmployer.orgAccesses);
      }
    });
  };

  const isDeactivatedUser = orgAccess.deactivated === true;

  // eslint-disable-next-line consistent-return
  const renderMenuItem = (): JSX.Element | undefined => {
    if (isDeactivatedUser)
      return (
        <SubmenuLink icon={AcceptedIcon} onClick={() => deactivateUser(false)} loading={loading}>
          Reactivate
        </SubmenuLink>
      );
    return (
      <SubmenuLink icon={DeclinedIcon} onClick={() => deactivateUser(true)} loading={loading}>
        Deactivate
      </SubmenuLink>
    );
  };

  return <>{renderMenuItem()}</>;
};

export default DeactivateUser;
