import * as React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { field } from 'a-plus-forms';
import { Select } from '@shortlyster/forms-kit';
import { QualificationType } from './types';

interface QualificationTypes {
  qualificationTypes?: QualificationType[];
}

const query = gql`
  query Qualifications {
    qualificationTypes {
      id
      name
    }
  }
`;

interface Props {
  value?: string;
  onChange?: (props: string | null) => void;
}

const QualificationTypeSelect: React.FC<Props> = ({ value: typeId, onChange, ...rest }) => {
  const { data, loading } = useQuery<QualificationTypes>(query);
  const handleChange = (qualificationType?: QualificationType) => {
    onChange && onChange(qualificationType ? qualificationType.id : null);
  };

  const { qualificationTypes = [] } = data ?? {};
  const value = qualificationTypes.find(t => t.id === typeId);

  return (
    <Select
      {...rest}
      options={qualificationTypes}
      loading={loading}
      value={value}
      onChange={handleChange}
      layout={null}
    />
  );
};

export default field()(QualificationTypeSelect);
