import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { getRequestId } from '../Sentry';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloProvider } from 'react-apollo';
import { useHistory } from 'react-router';

const metaTag = document.querySelector('meta[name="API_URL"]');
const metaUrl = metaTag ? metaTag.getAttribute('content') : undefined;

const uri = `${metaUrl || process.env.API_URL}/graphql`;
const AuthorizedApolloProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessTokenSilently();
    if (accessToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken}`,
          'x-request-id': getRequestId(),
        },
      };
    }
    return { headers };
  });

  const urlLink = new HttpLink({
    uri,
    credentials: 'include',
  });

  const errorLink = onError(error => {
    if ((error as any)?.networkError?.statusCode === 401) {
      loginWithRedirect({
        appState: {
          returnTo: history.location,
        },
      });
    }
  });
  const client = new ApolloClient({
    link: ApolloLink.from([authLink, urlLink, errorLink]),
    cache: new InMemoryCache(),
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
