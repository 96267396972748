import React, { useEffect } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';

import OrganisationsPage from 'src/features/Organisations/OrganisationsPage';
import NewOrganisationPage from 'src/features/Organisations/CreateOrganisationPage';
import OrganisationDetailsPage from 'src/features/Organisations/OrganisationDetails/OrganisationDetailsPage';
import OrganisationJobsPage from 'src/features/Organisations/OrganisationDetails/OrganisationJobsPage';
import OrganisationUsersPage from 'src/features/Organisations/OrganisationDetails/OrganisationUsersPage';
import OrganisationProductsPage from 'src/features/Organisations/OrganisationDetails/OrganisationProductsPage';

import AdminsPage from 'src/features/Admins/AdminsPage';
import NewAdminPage from 'src/features/Admins/NewAdminPage';

import EmployersPage from 'src/features/Employers/EmployersPage';
import NewEmployerPage from 'src/features/Employers/CreateEmployerPage';
import EditEmployerPage from 'src/features/Employers/UpdateEmployerPage';
import OrgAccessProvider from 'src/features/Employers/access/OrgAccessProvider';

import SkillsPage from 'src/features/Skills/SkillsPage';

import EditQualsPage from 'src/features/Qualifications/UpdateQualificationsPage';
import QualsPage from 'src/features/Qualifications/QualificationsPage';
import OrganisationIntegrationsPage from './features/Organisations/OrganisationDetails/OrganisationIntegrationsPage';

function useScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
}

const AppRouter = () => {
  useScrollToTop();

  return (
    <Switch>
      <Redirect exact path="/" to="/skills" />
      <Route path="/employers/new" exact>
        <OrgAccessProvider>
          <NewEmployerPage />
        </OrgAccessProvider>
      </Route>
      <Route path="/employers/:id" exact>
        <OrgAccessProvider>
          <EditEmployerPage />
        </OrgAccessProvider>
      </Route>
      <Route path="/employers" exact>
        <EmployersPage />
      </Route>
      <Route path="/admins" exact>
        <AdminsPage />
      </Route>
      <Route path="/admins/new" exact>
        <NewAdminPage />
      </Route>
      <Route path="/organisations/new" exact>
        <NewOrganisationPage />
      </Route>
      <Route path="/organisations/:id" exact>
        <OrganisationDetailsPage />
      </Route>
      <Route path="/organisations/:id/products" exact>
        <OrganisationProductsPage />
      </Route>
      <Route path="/organisations/:id/users" exact>
        <OrganisationUsersPage />
      </Route>
      <Route path="/organisations/:id/jobs" exact>
        <OrganisationJobsPage />
      </Route>
      <Route path="/organisations/:id/integrations" exact>
        <OrganisationIntegrationsPage />
      </Route>
      <Route path="/organisations" exact>
        <OrganisationsPage />
      </Route>
      <Route path="/skills" exact>
        <SkillsPage />
      </Route>
      <Route path="/qualifications/:id" exact>
        <EditQualsPage />
      </Route>
      <Route path="/qualifications" exact>
        <QualsPage />
      </Route>
    </Switch>
  );
};

export default AppRouter;
